const baseurl = process.env.REACT_APP_API_URL;
export const ApiPaths = {
  getAllGames: `/get_all_games`,
  login: `/login`,
  register: `/register`,
  checkSponsor: `/check_username`,
  getProfile: "/get_profile",
  paymentRequest: "/payment-request",
  getPaymentMethod: "/get-payment-method",
  getWallets: "/get_wallets",
  getPaymentTransaction: "/get-payment-transaction",
  getProviders: "/get_all_providers",
  getDocumentation: "/get_page",
  changePassword: "/update-password",
  withdraw: "/withdraw",
  getTopGames: "/get-top-games",
  getTeams: "/get_all_team",
  getPackages: "/get-all-package",
  getTeamSection: `/get_team_section`,
  placeBet: `/place-bet`,
  getColorGameHistory: "/get-game-history",
  getColorBetHistory: "/get-bet-history",
  p2pTransfer: "/fund_transfer",
  createTicket: "/create-ticket",
  getTickets: "/get-ticket",
  addBankDetails: "/add-bank-details",
  deleteBankDetails: "/delete-bank-details",
  defaultBankDetails: "/set-default-bank-details",
  getBankDetails: "/get-bank-details",
  addUpiDetails: "/add-upi-details",
  deleteUpiDetails: "/delete-upi-details ",
  defaultUpiDetails: "/set-default-upi-details",
  getUpiDetails: "/get-upi-details",
  getWebDetails: "/get-web3-details",
  addWebDetails: "/add-web3-details",
  deleteWebDetails: "/delete-web3-details",
  defaultWebDetails: "/set-default-web3-details",
  getAccountDetails: "/get-user-payment-details",
  sendOTP: "/send-otp",
};
