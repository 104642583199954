import React, { useEffect, useState } from "react";
import "./ColorBidPopup.css";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import RotateLoader from "../RotateLoader/RotateLoader";
const ColorBidPopup = ({ selectedData, onClose, gameId }) => {
  const [amount, setAmount] = useState(10);
  const [quantity, setQuantity] = useState(1);
  const [finalAmount, setFinalAmount] = useState();
  const { AxiosGet, AxiosPost } = useAxiosHelper();
  const [bidLoading, setBidLoading] = useState(false);

  useEffect(() => {
    setFinalAmount(amount * quantity);
  }, [amount, quantity]);

  const handleAmountChange = (value) => {
    setAmount(value);
  };

  async function PlaceBet() {
    console.log("11111111111111111", {
      betType: selectedData?.type,
      roundId: gameId,
      betAmount: finalAmount,
      betValue: selectedData?.value,
    });
    if (gameId) {
      setBidLoading(true);
      try {
        const body = {
          betType: selectedData?.type,
          roundId: gameId,
          betAmount: finalAmount,
          betValue: selectedData?.value,
        };
        const tempPlaceData = await AxiosPost(ApiPaths.placeBet, body);
        toastSuccess(tempPlaceData?.message);
        setBidLoading(false);
        onClose();
      } catch (e) {
        toastFailed(e?.response?.data?.message);
        setBidLoading(false);
      }
    } else {
      console.log(gameId);
    }
  }

  return (
    <div className="placeBidDetails">
      <div className="popup-container">
        <div className="popup">
          <div
            className="popup-header"
            style={{ background: selectedData?.color }}
          >
            <h2>Win Go 3Min</h2>
            <div id="popup-header-selected">
              <p>Select</p>
              <p style={{ textTransform: "capitalize" }}>
                {selectedData?.value} {selectedData?.type}
              </p>
            </div>
          </div>
          <div className="popup-body">
            <div className="amount-selection">
              <label>Contract : </label>
              {[10, 50, 100, 500, 1000].map((value) => (
                <button
                  className={amount === value && "placeBidDetailsAmountActive"}
                  key={value}
                  onClick={() => handleAmountChange(value)}
                >
                  {value}
                </button>
              ))}
            </div>
            <div className="flex-center my-4">
              <label>Quantity : </label>
              <div className="quantity-selection gap-2">
                <button
                  onClick={() => setQuantity(quantity - 1)}
                  disabled={quantity <= 1}
                >
                  -
                </button>
                <input
                  readOnly={true}
                  contentEditable={false}
                  type="number"
                  min={0}
                  max={100}
                  value={quantity}
                />
                <button onClick={() => setQuantity(quantity + 1)}>+</button>
              </div>
            </div>
          </div>
          <div className="popupFooter d-flex">
            <button className="cancel-button" onClick={onClose}>
              Cancel
            </button>
            <span>
              Amount {BasicInfo.currency}{" "}
              <input
                type="number"
                value={finalAmount}
                min="1"
                max="999999"
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);
                  if (value > 0 && value < 1000000) {
                    setFinalAmount(value);
                  } else if (value >= 1000000) {
                    setFinalAmount(999999); // Set to max value if input exceeds the limit
                  } else {
                    setFinalAmount(""); // Clear the input if it's below the minimum
                  }
                }}
              />
            </span>
            {bidLoading ? (
              <button id="bidLoader">
                <RotateLoader />
              </button>
            ) : (
              <button className="submit-button" onClick={PlaceBet}>
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorBidPopup;
