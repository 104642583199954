import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

const FullscreenDiv = () => {
    const divRef = useRef(null);

    const handleFullscreen = () => {
        if (!document.fullscreenElement) {
            if (divRef.current.requestFullscreen) {
                divRef.current.requestFullscreen();
            } else if (divRef.current.mozRequestFullScreen) { // Firefox
                divRef.current.mozRequestFullScreen();
            } else if (divRef.current.webkitRequestFullscreen) { // Chrome, Safari and Opera
                divRef.current.webkitRequestFullscreen();
            } else if (divRef.current.msRequestFullscreen) { // IE/Edge
                divRef.current.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
        }
    };

    return (
        <div>
            <div
                ref={divRef}
                style={{
                    width: '100px',
                    height: '100vh',
                    backgroundColor: 'lightblue',
                }}
            >
                <button onClick={handleFullscreen} style={{ position: 'absolute', top: 10, right: 10 }}>
                    Toggle Fullscreen
                </button>
                <Link to='/test2'>go</Link>
            </div>
        </div>
    );
};

export default FullscreenDiv;
