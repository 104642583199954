import React, { useEffect, useState } from "react";
import "./FundTransfer.css";
import { ApiPaths } from "../../Config/ApiPaths";
import useAxiosHelper from "../../Common/AxiosHalper";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setMainWallet, setFundWallet } from "../../Redux/ProfileDataSlice";
const FundTransfer = () => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [amount, setAmount] = useState("");
  const [userId, setUserId] = useState("");
  const [amountError, setAmountError] = useState("");
  const [userIdError, setUserIdError] = useState("");
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [otpError, setOtpError] = useState("");
  const [otpLoading, setOtpLoading] = useState(false);
  const [sponsorLoading, setSponsorLoading] = useState(false);
  const [checkSponsorExist, setCheckSponsorExist] = useState([]);
  const [status, setStatus] = useState("0");
  const [change, setChange] = useState();
  const { AxiosPost, AxiosGet } = useAxiosHelper();
  const dispatch = useDispatch();
  const mainWalletBalance = useSelector(
    (state) => state.profileData.mainWallet
  );
  const fundWalletBalance = useSelector(
    (state) => state.profileData.fundWallet
  );

  const handleShowFundHistory = (newStatus) => {
    setStatus(newStatus);
  };

  useEffect(() => {
    FetchData();
  }, []);

  async function FetchData() {
    try {
      const tempData = await AxiosGet(ApiPaths.getWallets);
      console.log("tempData", tempData);
      tempData?.wallets?.map((x, i) => {
        if (x?.slug == "fund_wallet") {
          dispatch(setFundWallet(x?.value));
        }
        if (x?.slug == "main_wallet") {
          dispatch(setMainWallet(x?.value));
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  //   async function TransferOTP() {
  //     try {
  //       setLoading(true);
  //       const body = {
  //         action: "transfer",
  //       };
  //       const tempOtpData = await AxiosPost(ApiPaths.sendOTP, body);
  //       console.log("tempOtpData", tempOtpData);
  //       toastSuccess(tempOtpData?.message);
  //       setLoading(false);
  //       setShowOtp(true);
  //     } catch (error) {
  //       console.log(error);
  //       toastFailed(error?.response?.data?.message);
  //       setLoading(false);
  //     }
  //   }

  async function FundTransferFunc() {
    setAmountError("");
    setUserIdError("");
    setPasswordError("");
    if (!amount > 0 || amount > mainWalletBalance) {
      setAmountError("Invalid Amount");
    }
    if (!userId.length > 0) {
      setUserIdError("Invalid User ID");
    }
    if (!password?.length > 0) {
      setPasswordError("Password Required");
    }
    const body = {
      username: userId,
      amount: amount,
      password: password,
    };
    console.log("body", body);
    if (
      amount > 0 &&
      userId.length > 0 &&
      password?.length > 0 &&
      amount <= mainWalletBalance
    ) {
      try {
        setLoading(true);
        const response = await AxiosPost(ApiPaths.p2pTransfer, body);
        toastSuccess(response?.message);
        setChange(Date.now());
        FetchData();
        setUserId("");
        setAmount("");
        setPassword("");
        console.log("response", response);
        setLoading(false);
      } catch (e) {
        toastFailed(e?.response?.data?.message);
        console.log("error", e);
        setLoading(false);
      }
    }
  }
  async function onUserStoppedTyping(sponID) {
    setSponsorLoading(true);
    try {
      const body = { username: sponID };
      const res = await AxiosPost(ApiPaths.checkSponsor, body);

      if (res.status == 200) {
        setCheckSponsorExist(true);
        setUsername(res?.name);
      }
    } catch (error) {
      BasicInfo.isDebug && console.error("error here", error);
      setUsername(null);
    } finally {
      setSponsorLoading(false);
    }
  }
  const handleInputChange = (e) => {
    setUserIdError("");
    const value = e.target.value;
    setUserId(value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        if (value.length > 0) {
          onUserStoppedTyping(value);
        } else {
          setUsername("");
        }
      }, 500)
    );
  };

  return (
    <section className="mainContent ">
      {loading ? <Loader /> : null}
      {/* {showOtp ? (
        <div className="otpSection">
          <div className="otpContainer">
            <h1>OTP</h1>
            <p>OTP sent to your registered email address</p>
            <input
              type="text"
              maxLength={6}
              size={6}
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <p className="errorMsg">{otpError}</p>
            {otpLoading ? (
              <div className="otpLoading"></div>
            ) : (
              <div>
                <button
                  className="btnSecondary"
                  onClick={() => (setOtp(""), setShowOtp(false))}
                >
                  Cancel
                </button>
                <button className="btnPrimary" onClick={FundTransferFunc}>
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      ) : null} */}
      <div className="addfundDiv inputPrimary withdrawalPage">
        <h1
          style={{
            fontSize: "20px",
            textAlign: "center",
            color: "var(--textHeading)",
          }}
        >
          Fund Transfer
        </h1>
        <div className="addfundDivFundWallet flex-center">
          <p>Main Wallet</p>
          <p>
            {BasicInfo?.currency}
            {parseFloat(mainWalletBalance).toFixed(2)}
          </p>
        </div>
        <div className="addfundDivFundWallet flex-center">
          <p>Fund Wallet</p>
          <p>
            {BasicInfo?.currency}
            {parseFloat(fundWalletBalance).toFixed(2)}
          </p>
        </div>
        <label htmlFor="Amount">User ID</label>
        {username?.length > 0 ? (
          <p id="sponsorVerified">{username}</p>
        ) : (
          userId?.length > 0 && (
            <p id="sponsorVerified" style={{ color: "red" }}>
              Not Exist
            </p>
          )
        )}
        <div className="loginInput_inner ">
          <input
            className="mainInput"
            style={{ borderRadius: "5px" }}
            min={1}
            required
            type="text"
            placeholder="User Id"
            value={userId}
            onChange={(e) => handleInputChange(e)}
          />
          {sponsorLoading ? <i id="sponsorLoading"></i> : null}
        </div>
        <p className="errorMsg">{userIdError}</p>
        <label htmlFor="Amount">Amount</label>
        <input
          className="mainInput"
          min={1}
          required
          type="number"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <p className="errorMsg">{amountError}</p>
        <label htmlFor="Amount">Password</label>
        <input
          className="mainInput"
          min={1}
          required
          type="password"
          placeholder="Enter Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <p className="errorMsg">{passwordError}</p>

        <button className="btnPrimary mt-3" onClick={FundTransferFunc}>
          Transfer
        </button>
      </div>
      {/* <section>
        <div>
          {status && <FundTransferHistory key={change} status={status} />}
        </div>
      </section> */}
    </section>
  );
};

export default FundTransfer;
