import React, { useState, useEffect, useRef } from "react";
import { BasicInfo } from "../../Config/BasicInfo";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import { useDispatch, useSelector } from "react-redux";
import { setFundWallet, setMainWallet } from "../../Redux/ProfileDataSlice";
const Timer = ({ onGameDataUpdate, onGameStop, onGameStart }) => {
  const [remainingTime, setRemainingTime] = useState(0);
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [username, setUsername] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState("Disconnected");
  const authToken = useSelector((state) => state.userStatus.authToken);
  const ws = useRef(null);
  const timerInterval = useRef(null);
  const retryTimeout = useRef(null);
  const { AxiosGet } = useAxiosHelper();
  const dispatch = useDispatch();
  let x = 0;
  useEffect(() => {
    if (x == 0) {
      getProfile();
      x = 1;
    }
  }, []);

  async function getProfile() {
    try {
      const tempProfile = await AxiosGet(ApiPaths.getProfile);
      setUsername(tempProfile?.username);
    } catch (error) {}
  }

  const connectWebSocket = () => {
    console.log("connectWebSocket");
    ws.current = new WebSocket("wss://apis.win2o.com"); // Replace with your server URL
    //ws.current = new WebSocket("wss://http://93.127.166.149:3009/"); // Replace with your server URL

    ws.current.onopen = () => {
      console.log("onopen");
      setConnectionStatus("Connected");
      // BasicInfo.isDebug && console.log("Connected to the server");

      // Send a message to the server once connected
      const message = { type: "token", token: authToken }; // Customize your message as needed
      ws.current.send(JSON.stringify(message)); // Send message to the server
    };

    ws.current.onmessage = (event) => {
      // console.log("onmessage");
      const tempData = JSON.parse(event.data);
      // BasicInfo.isDebug && console.log("tempData", tempData);
      if (tempData?.type == "balance") {
        // BasicInfo.isDebug && console.log("tempData111111111111111111111111", tempData);
        if (tempData?.type === "balance") {
          Array.isArray(tempData?.data) &&
            tempData?.data?.forEach((x) => {
              if (x?.slug === "fund_wallet") {
                dispatch(setFundWallet(x?.value));
              }
              if (x?.slug === "main_wallet") {
                dispatch(setMainWallet(x?.value));
              }
            });
        }
      } else if (tempData?.type === "betSummary") {
      } else if (
        tempData?.type === "currentGame" ||
        tempData?.type == "newRound"
      ) {
        setRemainingTime(tempData?.data?.remainingTime);
        onGameStart();
        onGameDataUpdate(tempData);
      } else {
        onGameDataUpdate(tempData);
      }
    };

    ws.current.onclose = () => {
      console.log("onclose");
      setConnectionStatus("Disconnected");
      // BasicInfo.isDebug && console.log("Disconnected from the server");
      retryConnection();
    };

    ws.current.onerror = (error) => {
      console.log("onerror");
      // BasicInfo.isDebug && console.error("WebSocket error:", error);
      retryConnection();
    };
  };

  const retryConnection = () => {
    console.log("retryConnection");
    if (retryTimeout.current) return;

    retryTimeout.current = setTimeout(() => {
      // BasicInfo.isDebug && console.log("Attempting to reconnect...");
      connectWebSocket();
      retryTimeout.current = null; // Reset the retry timeout reference
    }, 5000); // Reconnect after 5 seconds
  };

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
      clearInterval(timerInterval.current);
      clearTimeout(retryTimeout.current);
    };
  }, []);

  useEffect(() => {
    if (remainingTime > 0) {
      clearInterval(timerInterval.current); // Clear any previous interval
      timerInterval.current = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(timerInterval.current);
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    } else if (remainingTime === 0 || !remainingTime) {
      setMinutes("00");
      setSeconds("00");
    }
  }, [remainingTime]);

  useEffect(() => {
    if (remainingTime > 0) {
      const mins = Math.floor(remainingTime / 60000);
      const secs = Math.floor((remainingTime % 60000) / 1000);
      setMinutes(String(mins).padStart(2, "0"));
      setSeconds(String(secs).padStart(2, "0"));
    }
  }, [remainingTime]);

  // Effect to handle stopping the game when time is less than 3 seconds
  useEffect(() => {
    if (remainingTime <= 12000 && remainingTime > 0) {
      onGameStop(); // Call the onGameStop function to stop the game
    }
  }, [remainingTime, onGameStop]);

  return (
    <div>
      {minutes === "00" && seconds === "00" ? (
        <h4 style={{ color: "black" }}>
          {connectionStatus === "Disconnected"
            ? "Reconnecting..."
            : "Loading..."}
        </h4>
      ) : (
        <h1>
          {minutes}:{seconds}
        </h1>
      )}
    </div>
  );
};

export default Timer;
