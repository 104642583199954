import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./NumberPredictionGame.css";
import { FaPlay } from "react-icons/fa6";
import Ball from "./../../Assets/Images/ball.png";
import Ball8 from "./../../Assets/Images/ball3.png";
import ball0 from "./../../Assets/gameImages/ball0.png";
import ball1 from "./../../Assets/gameImages/ball1.png";
import ball2 from "./../../Assets/gameImages/ball2.png";
import ball3 from "./../../Assets/gameImages/ball3.png";
import ball4 from "./../../Assets/gameImages/ball4.png";
import ball5 from "./../../Assets/gameImages/ball5.png";
import ball6 from "./../../Assets/gameImages/ball6.png";
import ball7 from "./../../Assets/gameImages/ball7.png";
import ball8 from "./../../Assets/gameImages/ball8.png";
import ball9 from "./../../Assets/gameImages/ball9.png";
import Timer from "../../Components/Timer/Timer";
import Win from "./../../Assets/Images/win.png";
import Lose from "./../../Assets/Images/lose.png";
import moment from "moment/moment";
import BalanceContainer from "../../Components/BalanceContainer/BalanceContainer";
import ColorBidPopup from "../../Components/ColorBidPopup/ColorBidPopup";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import { BasicInfo } from "../../Config/BasicInfo";
import PaginationComponent from "../../Components/PaginationControls/PaginationControls";
import Accordion from "react-bootstrap/Accordion";
const NumberPredictionGame = () => {
  const [gameData, setGameData] = useState("");
  const [activeTab, setActiveTab] = useState("history");
  const [showPopup, setShowPopup] = useState(false);
  const [placeBidshowPopup, setPlaceBidShowPopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isGameActive, setIsGameActive] = useState(true);
  const { AxiosGet } = useAxiosHelper();
  const [gameHistory, setGameHistory] = useState([]);
  const [bidsHistory, setBidsHistory] = useState([]);
  const [historyCurrentPage, setHistoryCurrentPage] = useState(1);
  const [bidsCurrentPage, setBidsCurrentPage] = useState(1);
  const [historyTotalPage, setHistoryTotalPage] = useState();
  const [bidsTotalPage, setBidsTotalPage] = useState();

  const handleGameDataUpdate = (data) => {
    setGameData(data);
  };
  useEffect(() => {
    // console.log("gameData", gameData);
    if (gameData?.type == "roundEnd") {
      FetchGameHistory(historyCurrentPage);
      FetchBidsHistory(bidsCurrentPage);
      setShowPopup(true);

      setTimeout(() => {
        setShowPopup(false);
      }, 3000);
    }
  }, [gameData]);
  const handleColorClick = (data) => {
    if (isGameActive) {
      setSelectedData(data);
      setPlaceBidShowPopup(true);
    }
  };
  const closePopup = () => {
    setPlaceBidShowPopup(false);
  };
  const handleGameStop = () => {
    // console.log("Stopping game functionality...");
    setIsGameActive(false);
  };
  const handleGameStart = () => {
    // console.log("Starting game functionality...");
    setIsGameActive(true);
  };
  const numderData = [
    { value: 0, icon: ball0, color: "#ff9df7", type: "number" },
    { value: 1, icon: ball1, color: "#4caf50", type: "number" },
    { value: 2, icon: ball2, color: "#f53636", type: "number" },
    { value: 3, icon: ball3, color: "#4caf50", type: "number" },
    { value: 4, icon: ball4, color: "#f53636", type: "number" },
    { value: 5, icon: ball5, color: "#ff9df7", type: "number" },
    { value: 6, icon: ball6, color: "#f53636", type: "number" },
    { value: 7, icon: ball7, color: "#4caf50", type: "number" },
    { value: 8, icon: ball8, color: "#f53636", type: "number" },
    { value: 9, icon: ball9, color: "#4caf50", type: "number" },
  ];
  const colorData = [
    { value: "green", icon: Ball, color: "#4caf50", type: "color" },
    { value: "violet", icon: Ball, color: "violet", type: "color" },
    { value: "red", icon: Ball, color: "#f53636", type: "color" },
  ];
  const bigSmallData = [
    { value: "big", icon: Ball, color: "#4caf50", type: "size" },
    { value: "small", icon: Ball, color: "#4caf50", type: "size" },
  ];

  let x = 0;
  useEffect(() => {
    if (x === 0) {
      FetchGameHistory(historyCurrentPage);
      x = 1;
    }
  }, [historyCurrentPage]);

  let y = 0;
  useEffect(() => {
    if (y === 0) {
      FetchBidsHistory(bidsCurrentPage);
      y = 1;
    }
  }, [bidsCurrentPage]);

  async function FetchGameHistory(page) {
    console.log("page", page);
    try {
      const queryParams = {
        page: page || 1,
        limit: 20,
        status: 1,
      };
      const tempGameHistory = await AxiosGet(
        `${ApiPaths.getColorGameHistory}?${new URLSearchParams(
          queryParams
        ).toString()}`
      );
      // const tempHistory = await AxiosGet(
      //   `${ApiPaths.getColorGameHistory}?status=1`
      // );
      console.log("tempGameHistory", tempGameHistory);
      setGameHistory(tempGameHistory?.data);
      setHistoryCurrentPage(tempGameHistory?.currentPage);
      setHistoryTotalPage(tempGameHistory?.totalPages);
    } catch (error) {}
  }

  async function FetchBidsHistory(page) {
    console.log("page", page);
    try {
      const queryParams = {
        page: page || 1,
        limit: 20,
      };
      // const tempHistory = await AxiosGet(`${ApiPaths.getColorBetHistory}`);
      const tempBidsHistory = await AxiosGet(
        `${ApiPaths.getColorBetHistory}?${new URLSearchParams(
          queryParams
        ).toString()}`
      );
      console.log("bidsHistory", tempBidsHistory);
      setBidsHistory(tempBidsHistory?.data);
      setBidsCurrentPage(tempBidsHistory?.currentPage);
      setBidsTotalPage(tempBidsHistory?.totalPages);
    } catch (error) {}
  }

  const handleGameHistoryPagination = (page) => {
    console.log("eeeeeeeeeeeeeeeee", page);
    setHistoryCurrentPage(page);
  };
  const handleGameBidsPagination = (page) => {
    setBidsCurrentPage(page);
  };

  return (
    <section className="mainContent">
      <div className={`numberGamePopup ${showPopup ? "show" : "hide"}`}>
        <img
          src={numderData?.[gameData?.data?.drawnNumber]?.icon}
          alt={gameData?.data?.drawnNumber}
          style={{
            boxShadow: `0px 0px 100px 40px ${
              numderData?.[gameData?.data?.drawnNumber]?.color
            }`,
          }}
        />
      </div>
      <div className="centerGamingDiv">
        <Container>
          <div className="numberGameMainPage">
            <BalanceContainer />
            <div className="numTopSection">
              <Row>
                <Col xs="6">
                  <button className="borderButton">
                    <i className="d-flex">
                      <FaPlay />
                    </i>
                    <span>How to play</span>
                  </button>
                  <p className=" m-0 my-3">Win Go 3 Min</p>

                  <div className="flex-center image-oneline">
                    {gameHistory?.map((x, i) => {
                      if (i < 5) {
                        return (
                          <div>
                            <img
                              style={{ maxWidth: "100%" }}
                              src={numderData?.[x?.drawnNumber]?.icon}
                            ></img>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Col>

                <Col
                  xs="6"
                  className="d-flex align-items-center justify-content-end"
                >
                  <div className="numTimer">
                    <p>Time remaining</p>
                    <Timer
                      onGameDataUpdate={handleGameDataUpdate}
                      onGameStop={handleGameStop}
                      onGameStart={handleGameStart}
                    />
                    <h5>{gameData?.data?.game?.roundId}</h5>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="colorGamePlaySection">
              {isGameActive == false && <div id="gameDisable"></div>}
              <div className="numGameColorButtons">
                {colorData?.map((x) => {
                  return (
                    <button
                      onClick={() => handleColorClick(x)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {x?.value}
                    </button>
                  );
                })}
              </div>
              <div className="flex-center image-oneline flex-wrap numberGameNum">
                {numderData?.map((x) => {
                  return (
                    <img src={x.icon} onClick={() => handleColorClick(x)}></img>
                  );
                })}
              </div>
              <div className="numberGameBigSmall">
                {bigSmallData?.map((x) => {
                  return (
                    <button
                      onClick={() => handleColorClick(x)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {x?.value}
                    </button>
                  );
                })}
              </div>
            </div>

            <div className="flex-center gap-2 historyAndBids">
              <button
                className={activeTab == "history" && "historyAndBidsActive"}
                onClick={() => setActiveTab("history")}
              >
                Game History
              </button>
              <button
                className={activeTab == "bids" && "historyAndBidsActive"}
                onClick={() => setActiveTab("bids")}
              >
                My Bids
              </button>
            </div>
            {activeTab == "history" ? (
              <div className="colorGameHistory">
                <table>
                  <thead>
                    <th>Period</th>
                    <th>Number</th>
                    <th>Big Small</th>
                    <th>Color</th>
                  </thead>
                  <tbody>
                    {gameHistory?.map((x, i) => {
                      return (
                        <tr>
                          <td>{x?.roundId}</td>
                          <td
                            style={{
                              fontSize: "24px",
                              fontWeight: "800",
                              color:
                                x?.drawnColor == "red"
                                  ? "var(--gameRed)"
                                  : x?.drawnColor == "green"
                                  ? "var(--gameGreen)"
                                  : "var(--gameViolet)",
                            }}
                          >
                            {x?.drawnNumber}
                          </td>
                          <td>{x?.drawnSize}</td>
                          <td style={{ textAlign: "center" }}>
                            {x?.drawnNumber == 0 ? (
                              <div className="d-flex align-items-center justify-content-center">
                                <p
                                  style={{
                                    margin: "0",
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    background: "var(--gameViolet)",
                                  }}
                                ></p>
                                <p
                                  style={{
                                    margin: "0",
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    background: "var(--gameRed)",
                                  }}
                                ></p>
                              </div>
                            ) : x?.drawnNumber == 5 ? (
                              <div className="d-flex align-items-center justify-content-center">
                                <p
                                  style={{
                                    margin: "0",
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    background: "var(--gameViolet)",
                                  }}
                                ></p>
                                <p
                                  style={{
                                    margin: "0",
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    background: "var(--gameGreen)",
                                  }}
                                ></p>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center justify-content-center">
                                <p
                                  style={{
                                    margin: "0",
                                    width: "15px",
                                    height: "15px",
                                    borderRadius: "50%",
                                    background:
                                      x?.drawnColor == "red"
                                        ? "var(--gameRed)"
                                        : x?.drawnColor == "green"
                                        ? "var(--gameGreen)"
                                        : "var(--gameViolet)",
                                  }}
                                ></p>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {gameHistory?.length > 1 && (
                  <PaginationComponent
                    currentPage={historyCurrentPage}
                    totalPages={historyTotalPage}
                    onPageChange={handleGameHistoryPagination}
                  />
                )}
              </div>
            ) : (
              //  (
              //   <div className="colorBetHistory">
              //     {bidsHistory?.map((x) => {
              //       return (
              //         <div className="colorBetHistoryCard">
              //           <div className="colorBetHistoryCardLeft">
              //             <div className="text-center">
              //               <img src={x?.isWin ? Win : Lose} alt="win" />
              //               <p style={{ fontWeight: "600" }}>
              //                 {x?.isWin ? "win" : "lose"}
              //               </p>
              //             </div>
              //             <div>
              //               <p>ID : {x?.roundId}</p>
              //             </div>
              //           </div>
              //           <div className="colorBetHistoryCardRight">
              //             {x?.isWin ? (
              //               <p
              //                 style={{
              //                   color: "green",
              //                   fontSize: "18px",
              //                   fontWeight: "700",
              //                 }}
              //               >
              //                 {BasicInfo.currency} +{x?.betAmount}
              //               </p>
              //             ) : (
              //               <p
              //                 style={{
              //                   color: "red",
              //                   fontSize: "18px",
              //                   fontWeight: "700",
              //                 }}
              //               >
              //                 {BasicInfo.currency} -{x?.betAmount}
              //               </p>
              //             )}
              //             <p>{moment(x?.time).format("llll")}</p>
              //           </div>
              //         </div>
              //       );
              //     })}
              //     <PaginationComponent
              //       currentPage={bidsCurrentPage}
              //       totalPages={bidsTotalPage}
              //       onPageChange={handleGameBidsPagination}
              //     />
              //   </div>
              // )}
              <div className="mt-2">
                {bidsHistory?.map((x, i) => {
                  return (
                    <Accordion defaultActiveKey="0" className="mb-2">
                      <Accordion.Item eventKey="i">
                        <Accordion.Header>
                          <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="colorBetHistoryCard">
                              <div className="colorBetHistoryCardLeft">
                                <div className="text-center">
                                  <img src={x?.isWin ? Win : Lose} alt="win" />
                                  <p style={{ fontWeight: "600" }}>
                                    {x?.isWin ? "win" : "lose"}
                                  </p>
                                </div>
                              </div>
                              <p>Period: {x?.roundId}</p>
                            </div>
                            <p
                              className="m-0 mx-2"
                              style={{
                                fontSize: "10px",
                                textTransform: "capitalize",
                                color: "var(--lightYellow)",
                                fontWeight: "600",
                              }}
                            >
                              {x?.status}
                            </p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="ColorGameAccordion">
                            <div>
                              <p>Period</p>
                              <p>{x?.roundId}</p>
                            </div>
                            <div>
                              <p>Bet Placed</p>
                              <p>
                                {x?.betValue} {x?.betType}
                              </p>
                            </div>

                            <div>
                              <p>Bet Amount</p>
                              <p>
                                {BasicInfo.currency}
                                {x?.betAmount}
                              </p>
                            </div>
                            <div>
                              <p>Win/Loss</p>
                              {x?.isWin ? (
                                <p
                                  style={{
                                    color: "var(--successColor)",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Win {BasicInfo.currency} {x?.win_amount}
                                </p>
                              ) : (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                  }}
                                >
                                  Loss {BasicInfo.currency} {x?.betAmount}
                                </p>
                              )}
                            </div>
                            <div>
                              <p>Date & Time</p>
                              <p>{moment(x?.time).format("llll")}</p>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
                <PaginationComponent
                  currentPage={bidsCurrentPage}
                  totalPages={bidsTotalPage}
                  onPageChange={handleGameBidsPagination}
                />
              </div>
            )}
          </div>
        </Container>
        {placeBidshowPopup && (
          <ColorBidPopup
            selectedData={selectedData}
            onClose={closePopup}
            gameId={gameData?.data?.game?.roundId}
          />
        )}
      </div>
    </section>
  );
};

export default NumberPredictionGame;
