import React, { useState } from "react";
import "./PaymentMethod.css";
import img10 from "./../../Assets/Payment/Ethereum.svg";
import upi from "./../../Assets/Payment/upi.webp";
import Razorpay from "./../../Assets/Payment/razorpay.png";
import Bank from "./../../Assets/Payment/bank.png";
import Metamask from "./../../Assets/Payment/metamask.png";
import { RxCross1 } from "react-icons/rx";
import { AiFillHome } from "react-icons/ai";
import coinpayments from "./../../Assets/Payment/coinpayments.svg";
import ERC20 from "./../../Assets/Payment/Tether-USD-ERC20-token.svg";
import TRC20 from "./../../Assets/Payment/Tether-USD-TRC20-token(1).svg";
import { useNavigate } from "react-router-dom";
import { toastFailed } from "../../Config/BasicInfo";
const PaymentMethod = ({ fun }) => {
  const [paymentType, setPaymentType] = useState(null);
  const [formDepositAmount, setFormDepositAmount] = useState(300);
  const navigate = useNavigate();
  const paymentData = [
    { logo: upi, height: "150%", width: "150%", type: "upi" },
    { logo: Bank, height: "60%", width: "60%", type: "bank" },
    // { logo: img1, height: '100%', width: "100%" },
    // { logo: img2, height: '100%', width: "100%" },
    // { logo: img3, height: '100%', width: "100%" },
    // { logo: img4, height: '100%', width: "100%" },
    // { logo: hdfc, height: '60%', width: "60%" },
  ];
  const apiData = [{ logo: coinpayments }, { logo: Razorpay }];
  const web3Data = [
    { logo: img10, height: "100%", width: "100%" },
    { logo: ERC20, height: "100%", width: "100%" },
    { logo: TRC20, height: "100%", width: "100%" },
  ];

  // const handleDeposit = () => {
  //     if (formDepositAmount > 0) {
  //         const data = { amount: formDepositAmount };
  //         const queryParams = new URLSearchParams(data).toString();
  //         if (paymentType === "upi") {
  //             window.location.href = `/payment-upi?${queryParams}`;
  //         } else {
  //             window.location.href = `/payment-bank?${queryParams}`;
  //         }
  //     } else {
  //         toastFailed("Invalid amount");
  //     }
  // }
  const handleDeposit = () => {
    fun();
    if (formDepositAmount > 0) {
      const queryParams = new URLSearchParams({
        amount: formDepositAmount,
      }).toString();
      if (paymentType === "upi") {
        navigate(`/payment-upi?${queryParams}`);
      } else {
        navigate(`/payment-bank?${queryParams}`);
      }
    } else {
      toastFailed("Invalid amount"); // Assuming you're using react-toastify for toasts
    }
  };

  return (
    <section className="depositPaymentMethod">
      <div className="depositPaymentMethodContent">
        <div id="depositPaymentMethodContentHeading">
          <p>Deposit</p>
          <i onClick={() => fun()}>
            <RxCross1 />
          </i>
        </div>
        <div style={{ position: "relative" }}>
          <h5 id="depositPaymentMethodContentSubHeading">
            Select payment method
          </h5>
          {paymentType !== null && (
            <i id="paymentBackButton" onClick={() => setPaymentType(null)}>
              <AiFillHome />
            </i>
          )}
        </div>
        <section className="paymentsLogosSection ">
          {paymentType == null && (
            <div className="paymentsLogos paymentsLogosCustomLogo">
              <div onClick={() => setPaymentType("manual")}>
                <img src={Bank} alt="Bank" />
              </div>
              <div onClick={() => setPaymentType("api")}>
                <img src={Razorpay} alt="" />
              </div>
              <div onClick={() => setPaymentType("web3")}>
                <img src={Metamask} alt="" />
              </div>
            </div>
          )}
          {paymentType == "manual" && (
            <div className="paymentsLogos paymentsLogosCustomLogo">
              {paymentData.map((x, index) => (
                <div
                  key={index}
                  onClick={() => (console.log(x.type), setPaymentType(x.type))}
                >
                  <img
                    src={x.logo}
                    style={{ width: x.width, height: x.height }}
                  />
                </div>
              ))}
            </div>
          )}
          {paymentType == "api" && (
            <div className="paymentsLogos paymentsLogosCustomLogo">
              {apiData.map((x, index) => (
                <div key={index}>
                  <img src={x.logo} alt="" />
                </div>
              ))}
            </div>
          )}
          {paymentType == "web3" && (
            <div className="paymentsLogos paymentsLogosCustomLogo">
              {web3Data.map((x, index) => (
                <div key={index}>
                  <img
                    src={x.logo}
                    style={{ width: x.width, height: x.height }}
                  />
                </div>
              ))}
            </div>
          )}
          {(paymentType == "upi" || paymentType == "bank") && (
            <div className="selectAmontBeforeTransaction">
              <h1>DEPOSIT</h1>
              <p>Please choose or enter deposit amount</p>

              <div className="chooseAmountFromTabs">
                <span onClick={() => setFormDepositAmount(300)}>300 INR</span>
                <span onClick={() => setFormDepositAmount(500)}>500 INR</span>
                <span onClick={() => setFormDepositAmount(1000)}>1000 INR</span>
                <span onClick={() => setFormDepositAmount(1500)}>1500 INR</span>
                <span onClick={() => setFormDepositAmount(3000)}>3000 INR</span>
                <span onClick={() => setFormDepositAmount(5000)}>5000 INR</span>
              </div>
              <p className="m-0 mt-3">Enter amount</p>
              <div id="selectAmontBeforeTransactionInput">
                <input
                  type="number"
                  value={formDepositAmount}
                  onChange={(e) =>
                    e.target.value <= 100000 &&
                    setFormDepositAmount(e.target.value)
                  }
                />
                <span>INR</span>
              </div>
              <p>
                Max. deposit: <h5>100000 INR</h5>
              </p>
              <button
                className="btnPrimary mb-4"
                style={{ borderRadius: "5px" }}
                onClick={handleDeposit}
              >
                DEPOSIT
              </button>
            </div>
          )}
        </section>
      </div>
    </section>
  );
};

export default PaymentMethod;
