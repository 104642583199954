import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { Col, Container, Row } from "react-bootstrap";
import { MdOutlineModeEdit } from "react-icons/md";
import { BasicInfo, toastFailed, toastSuccess } from "./../../Config/BasicInfo";
import imageCompression from "browser-image-compression";
import "./Support.css";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
const Support = () => {
  const { AxiosPost, AxiosGet } = useAxiosHelper();
  const [files, setFiles] = useState([]);
  const [ticketType, setTicketType] = useState();
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [previews, setPreviews] = useState([]);
  const [ticketsData, setTicketsData] = useState([]);
  const onDrop = async (acceptedFiles) => {
    const compressedFiles = await Promise.all(
      acceptedFiles.map(async (file) => {
        const options = {
          maxSizeMB: 1, // Maximum file size in MB
          maxWidthOrHeight: 1024, // Maximum width or height in pixels
          useWebWorker: true,
        };
        try {
          return await imageCompression(file, options);
        } catch (err) {
          console.error("Error compressing image:", err);
          return file;
        }
      })
    );

    setFiles(compressedFiles);
    setPreviews(compressedFiles.map((file) => URL.createObjectURL(file)));
  };
  const uploadFiles = async () => {
    setUploading(true);
    setError(null);

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("attachments", file);
    });

    // Append additional data
    formData.append("subject", ticketType);
    formData.append("description", description);

    try {
      console.log("formData", formData);
      const response = await AxiosPost(ApiPaths.createTicket, formData);
      BasicInfo.isDebug && console.log("Upload success:", response);
      setFiles([]);
      setPreviews([]);
      setTicketType("");
      setDescription("");
      toastSuccess(response?.message);
      FetchTickets();
    } catch (err) {
      console.error("Upload error:", err);
      setError("Failed to upload files. Please try again.");
      toastFailed(err?.response?.data?.message);
    } finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    FetchTickets();
  }, []);

  async function FetchTickets() {
    try {
      const res = await AxiosGet(ApiPaths?.getTickets);
      console.log("res", res);
      setTicketsData(res);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="mainContent ">
      <Container className="pt-1">
        <Row className="mt-5">
          <Col md="12" className="mb-4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Ticket Type</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                placeholder="Deposit / Withdrawal / Income etc..."
                value={ticketType}
                onChange={(e) => setTicketType(e.target.value)}
              />
              <i id="myProfileInputFieldIcon">
                <MdOutlineModeEdit />
              </i>
            </div>
          </Col>
          <Col md="12">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Description</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                placeholder="Please write your query here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <i id="myProfileInputFieldIcon">
                <MdOutlineModeEdit />
              </i>
            </div>
          </Col>
        </Row>
        <div className="file-upload-container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p>Drop your files here</p>
          </div>
          <button
            className="upload-button"
            onClick={uploadFiles}
            disabled={uploading || files.length === 0}
          >
            <i>
              <FiUploadCloud />
            </i>
            {uploading ? "Submitting..." : "Submit"}
          </button>
          {error && <p className="error-message">{error}</p>}
          <div className="previews">
            {previews.map((preview, index) => (
              <div key={index} className="preview">
                <img src={preview} alt={`Preview ${index}`} />
              </div>
            ))}
          </div>
        </div>
        <div className="table supportTable">
          <table className="w-100">
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Ticket Type</th>
                <th>Description</th>
                <th>Date & Time</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(ticketsData) &&
                ticketsData?.map((x, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{x?.subject}</td>
                      <td style={{ maxWidth: "250px" }}>{x?.description}</td>
                      <td>{x?.created_at}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};

export default Support;
