import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./UpiDetails.css";
import { ApiPaths } from "../../Config/ApiPaths";
import { toastFailed, toastSuccess } from "../../Config/BasicInfo";
import useAxiosHelper from "../../Common/AxiosHalper";
import Select from "react-select";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import { BasicInfo } from "../../Config/BasicInfo";
const UpiDetails = () => {
  const [name, setName] = useState("");
  const [upiId, setUpiId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("null");
  const [upiData, setUpiData] = useState([]);
  const [defaultUpiId, setDefaultUpiId] = useState(null);
  const { AxiosGet, AxiosPost } = useAxiosHelper();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const options = [
    { value: "bank", label: "Bank Details" },
    { value: "upi", label: "Upi Details" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--containerColor)",
      borderColor: "var(--containerColor)",
      padding: "4px",
      fontSize: "16px",
      borderRadius: "30px",
      marginBottom: "20px",
      color: "#fff",
    }),
  };
  const [selectedOption, setSelectedOption] = useState(options[0]);
  var x = 0;

  useEffect(() => {
    if (x == 0) {
      fetchUpiData();
      x++;
    }
  }, []);

  const fetchUpiData = async () => {
    try {
      setLoading(true);
      const response = await AxiosGet(ApiPaths.getUpiDetails);
      BasicInfo.isDebug && console.log(response, "upi details");
      if (response) {
        setUpiData(response?.upiDetails);
        const defaultUpi = response?.upiDetails.find((upi) => upi.isDefault);
        setDefaultUpiId(defaultUpi?.upiId || null);
        toastSuccess(response?.message);
      }
    } catch (error) {
      console.log(error);
      toastFailed(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!name) {
      formErrors.name = "Name is required";
      isValid = false;
    }

    if (!upiId) {
      formErrors.upiId = "UPI ID is required";
      isValid = false;
    } else if (!/^[\w.-]+@[\w.-]+$/.test(upiId)) {
      formErrors.upiId = "Invalid UPI ID format";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const body = { name, upiId };
        const addupidetails = await AxiosPost(ApiPaths.addUpiDetails, body);
        console.log(addupidetails);
        if (addupidetails) {
          fetchUpiData();
          toastSuccess(addupidetails?.message);
          setName("");
          setUpiId("");
          setErrors({});
        }
      } catch (error) {
        console.log(error);
        toastFailed(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (upiId) => {
    try {
      setLoading(true);
      const body = { upiId };
      const deleteupidetails = await AxiosPost(ApiPaths.deleteUpiDetails, body);
      console.log(deleteupidetails);
      if (deleteupidetails) {
        fetchUpiData();
        toastSuccess(deleteupidetails?.message);
      }
    } catch (error) {
      toastFailed(error?.response?.data?.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDefault = async (upiId) => {
    try {
      setLoading(true);
      const body = { upiId };
      const defaultupidetails = await AxiosPost(
        ApiPaths.defaultUpiDetails,
        body
      );
      console.log(defaultupidetails);
      if (defaultupidetails) {
        fetchUpiData();
        toastSuccess(defaultupidetails?.message);
      }
    } catch (error) {
      console.log(error);
      toastFailed(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="mainContent">
      <ProfileHeader />
      <section className="incomeContent">
        <section className="paymentHistoryTabs">
          <div>
            <h1 onClick={() => navigate("/account-details")}>Bank </h1>
            <h1
              className="activePaymentHistoryTab"
              onClick={() => navigate("/upi-details")}
            >
              UPI
            </h1>
            {/* <h1 onClick={() => navigate("/web-details")}>Crypto</h1> */}
          </div>
        </section>
        <section id="upiForm">
          <div
            style={{ marginTop: "5%" }}
            className="d-flex justify-content-center"
          >
            <h2 className="heading">UPI Form</h2>
          </div>
          <section id="formContent">
            <div className="form">
              <Row>
                <Col lg={6} style={{ margin: "auto" }}>
                  <form onSubmit={handleSubmit} className="registerForm">
                    <div id="formFields">
                      <label htmlFor="name">Name</label>

                      <div className="mb-3 myProfileInputField">
                        <input
                          type="text"
                          id="name"
                          className={`form-control  ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          value={name}
                          placeholder="Enter your name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="upiId">UPI ID</label>
                        <div className="mb-3 myProfileInputField">
                          <input
                            type="text"
                            id="upiId"
                            className={`form-control ${
                              errors.upiId ? "is-invalid" : ""
                            }`}
                            value={upiId}
                            placeholder="Enter your UPI ID"
                            onChange={(e) => setUpiId(e.target.value)}
                            required
                          />
                          {errors.upiId && (
                            <div className="invalid-feedback">
                              {errors.upiId}
                            </div>
                          )}
                        </div>
                      </div>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit"}
                      </Button>
                      <Button
                        variant="secondary"
                        className="ms-2"
                        onClick={() => {
                          setName("");
                          setUpiId("");
                          setErrors({});
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </section>

          <section className="history">
            <div className="d-flex gap-2"></div>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>S No.</th>
                    <th>Name</th>
                    <th>UPI ID</th>
                    {/* <th>Delete UPI</th>
                    <th>Set Default</th> */}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(upiData) &&
                    upiData?.map((x, i) => (
                      <tr key={i}>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{x?.name}</td>
                        <td className="text-center">{x?.upiId}</td>
                        {/* <td className="text-center">
                          <button
                            className="block-btn"
                            onClick={() => handleDelete(x?.upiId)}
                          >
                            Delete UPI
                          </button>
                        </td>
                        <td className="text-center">
                          <button
                            className={`block-btn ${
                              defaultUpiId === x?.upiId ? "default-btn" : ""
                            }`}
                            onClick={() => handleDefault(x?.upiId)}
                            disabled={defaultUpiId === x?.upiId}
                          >
                            {defaultUpiId === x?.upiId
                              ? "Default"
                              : "Set Default"}
                          </button>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default UpiDetails;
