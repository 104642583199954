import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IoCopy } from "react-icons/io5";
import { BasicInfo } from "../../Config/BasicInfo";
import { setUserPersonalInfo } from "../../Redux/ProfileDataSlice";
import CopyFromtag from "../../Common/CopyFromtag";
import ReferImg from "./../../Assets/Images/refer_ern.png";
import { useDispatch, useSelector } from "react-redux";
import { ApiPaths } from "../../Config/ApiPaths";
import useAxiosHelper from "../../Common/AxiosHalper";
export default function Refer() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  var x = 0;
  useEffect(() => {
    if (x === 0) {
      FetchProfile();
      x++;
    }
  }, []);
  const profileData = useSelector(
    (state) => state.profileData.userPersonalInfo
  );
  const [isCopied, setIsCopied] = useState(false);
  const handleCopy = (id) => {
    CopyFromtag(id);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000); // Reset copied state after 2 seconds
  };
  const { AxiosGet } = useAxiosHelper();
  const FetchProfile = async () => {
    if (profileData == null || profileData?.length == 0) {
      try {
        setLoading(true);
        const tempProfileData = await AxiosGet(ApiPaths.getProfile);
        BasicInfo.isDebug && console.log("tempProfileData", tempProfileData);
        dispatch(setUserPersonalInfo(tempProfileData));
      } catch (e) {
        console.error("e", e);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <section className="myProfile">
      <div className="iconHeadingAndLink">
        <div className="iconHeading">
          <p>Refer And Earn</p>
        </div>
      </div>
      <section className="referAndEarn">
        <Row style={{ width: "100%" }}>
          <Col md="6">
            <div className="referAndEarnLeft">
              <img src={ReferImg} alt="refer" />
            </div>
          </Col>
          <Col md="6" className="p-0">
            <div className="referAndEarnRight">
              <p>Your referral Link</p>
              <div>
                <p id="referLinkCopy">{`https://win2o.com?ref=${profileData?.username}`}</p>

                <div onClick={() => handleCopy("referLinkCopy")}>
                  <i>
                    <IoCopy />
                  </i>
                  {isCopied ? (
                    <p className="copied-animation">Copied!</p>
                  ) : (
                    <p>Copy</p>
                  )}
                </div>
              </div>
              <h5>
                Share your referral link with Your <br /> frinds and get
                benifits.
              </h5>
            </div>
          </Col>
        </Row>
      </section>
    </section>
  );
}
