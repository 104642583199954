import Logo from "./../Assets/Images/logo.png";
import LogoIcon from "./../Assets/Images/logoIcon.png";
import { toast } from "react-toastify";
import smallGameBanner from "./../Assets/Images/gamesmallbanner.png";
export const BasicInfo = {
  logo: Logo,
  logoIcon: LogoIcon,
  projectName: "Win2.0",
  DefaultGameBanner: smallGameBanner,
  isDebug: true,
  currency:"₹"
};
export const toastSuccess = (msg) => toast.success(msg);
export const toastFailed = (msg) => toast.error(msg);
export const toastInfo = (msg) => toast.info(msg);
