import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./AccountDetails.css";
import { ApiPaths } from "../../Config/ApiPaths";
import { toastFailed, toastSuccess } from "../../Config/BasicInfo";
import useAxiosHelper from "../../Common/AxiosHalper";
import Select from "react-select";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";

const BankDetails = () => {
  const [bankName, setBankName] = useState("");
  const [ifsc, setIfscCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [holder, setAccountHolderName] = useState("");
  const [ac_type, setAccountType] = useState("");
  const [branch, setBankBranch] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [bankData, setBankData] = useState([]);
  const [defaultBankId, setDefaultBankId] = useState(null);
  const { AxiosGet, AxiosPost } = useAxiosHelper();
  const navigate = useNavigate();

  useEffect(() => {
    fetchBankData();
  }, []);

  const fetchBankData = async () => {
    try {
      setLoading(true);
      const response = await AxiosGet(ApiPaths.getBankDetails);
      if (response) {
        setBankData(response?.bankDetails || []);
        const defaultBank = response?.bankDetails.find(
          (account) => account.isDefault
        );
        setDefaultBankId(defaultBank?.accountNumber || null);
        toastSuccess(response?.message);
      }
    } catch (error) {
      toastFailed(
        error?.response?.data?.message || "Failed to fetch bank data"
      );
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!bankName) {
      formErrors.bankName = "Bank Name is required";
      isValid = false;
    }
    if (!ifsc) {
      formErrors.ifscCode = "IFSC Code is required";
      isValid = false;
    } else if (!/^[A-Z]{4}[0][A-Z0-9]{6}$/.test(ifsc)) {
      formErrors.ifscCode = "Invalid IFSC Code format";
      isValid = false;
    }
    if (!accountNumber) {
      formErrors.accountNumber = "Account Number is required";
      isValid = false;
    } else if (!/^\d+$/.test(accountNumber)) {
      formErrors.accountNumber = "Account Number must be numeric";
      isValid = false;
    }
    if (!holder) {
      formErrors.accountHolderName = "Account Holder Name is required";
      isValid = false;
    }
    if (!ac_type) {
      formErrors.accountType = "Account Type is required";
      isValid = false;
    }
    if (!branch) {
      formErrors.bankBranch = "Bank Branch is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const body = { bankName, ifsc, accountNumber, holder, ac_type, branch };
        const response = await AxiosPost(ApiPaths.addBankDetails, body);
        if (response) {
          toastSuccess(response?.message);
          fetchBankData();
          setBankName("");
          setIfscCode("");
          setAccountNumber("");
          setAccountHolderName("");
          setAccountType("");
          setBankBranch("");
        }
      } catch (e) {
        toastFailed(e?.response?.data?.message || "Failed to add bank details");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDelete = async (accountNumber) => {
    try {
      setLoading(true);
      const body = { accountNumber };
      const deleteDetails = await AxiosPost(ApiPaths.deleteBankDetails, body);
      if (deleteDetails) {
        toastSuccess(deleteDetails?.message);
        fetchBankData();
      }
    } catch (error) {
      toastFailed(
        error?.response?.data?.message || "Failed to delete bank details"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDefault = async (accountNumber) => {
    try {
      setLoading(true);
      const body = { accountNumber };
      const setBankDetails = await AxiosPost(ApiPaths.defaultBankDetails, body);
      if (setBankDetails) {
        toastSuccess(setBankDetails?.message);
        fetchBankData();
      }
    } catch (error) {
      toastFailed(
        error?.response?.data?.message || "Failed to set default bank details"
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="mainContent">
      <ProfileHeader />
      <section className="incomeContent">
        <section className="paymentHistoryTabs">
          <div>
            <h1
              className="activePaymentHistoryTab"
              onClick={() => navigate("/account-details")}
            >
              Bank
            </h1>
            <h1 onClick={() => navigate("/upi-details")}>UPI</h1>
            {/* <h1 onClick={() => navigate("/web-details")}>Crypto</h1> */}
          </div>
        </section>
        <div
          style={{ marginTop: "5%" }}
          className="d-flex justify-content-center "
        >
          <h2 className="heading">Bank Details Form</h2>
        </div>
        <section id="formContent">
          {/* <div>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={customStyles}
              id="betSelectBox"
            />
          </div> */}
          <div className="form">
            <Row style={{ justifyContent: "center" }}>
              <Col lg={6}>
                <form onSubmit={handleSubmit} className="registerForm">
                  <div id="formFields">
                    <div className="mb-3">
                      <label htmlFor="bankName">Bank Name</label>
                      <div className="mb-3 myProfileInputField">
                        <input
                          type="text"
                          id="bankName"
                          className={`form-control ${
                            errors.bankName ? "is-invalid" : ""
                          }`}
                          value={bankName}
                          placeholder="Enter your Bank name"
                          onChange={(e) => setBankName(e.target.value)}
                        />
                        {errors.bankName && (
                          <div className="invalid-feedback">
                            {errors.bankName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="ifscCode">IFSC Code</label>
                      <div className="mb-3 myProfileInputField">
                        <input
                          type="text"
                          id="ifscCode"
                          className={`form-control ${
                            errors.ifscCode ? "is-invalid" : ""
                          }`}
                          value={ifsc}
                          placeholder="Enter your IFSC Code"
                          onChange={(e) => setIfscCode(e.target.value)}
                        />
                        {errors.ifscCode && (
                          <div className="invalid-feedback">
                            {errors.ifscCode}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="accountNumber">Account Number</label>
                      <div className="mb-3 myProfileInputField">
                        <input
                          type="number"
                          id="accountNumber"
                          className={`form-control ${
                            errors.accountNumber ? "is-invalid" : ""
                          }`}
                          value={accountNumber}
                          placeholder="Enter Account Number"
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                        {errors.accountNumber && (
                          <div className="invalid-feedback">
                            {errors.accountNumber}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="accountHolderName">
                        Account Holder Name
                      </label>
                      <div className="mb-3 myProfileInputField">
                        <input
                          type="text"
                          id="accountHolderName"
                          className={`form-control ${
                            errors.accountHolderName ? "is-invalid" : ""
                          }`}
                          value={holder}
                          placeholder="Enter Account Holder Name"
                          onChange={(e) => setAccountHolderName(e.target.value)}
                        />
                        {errors.accountHolderName && (
                          <div className="invalid-feedback">
                            {errors.accountHolderName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="accountType">Account Type</label>
                      <div className="mb-3 myProfileInputField">
                        <input
                          type="text"
                          id="accountType"
                          className={`form-control ${
                            errors.accountType ? "is-invalid" : ""
                          }`}
                          value={ac_type}
                          placeholder="Enter Account Type"
                          onChange={(e) => setAccountType(e.target.value)}
                        />
                        {errors.accountType && (
                          <div className="invalid-feedback">
                            {errors.accountType}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="bankBranch">Bank Branch</label>
                      <div className="mb-3 myProfileInputField">
                        <input
                          type="text"
                          id="bankBranch"
                          className={`form-control ${
                            errors.bankBranch ? "is-invalid" : ""
                          }`}
                          value={branch}
                          placeholder="Enter Bank Branch"
                          onChange={(e) => setBankBranch(e.target.value)}
                        />
                        {errors.bankBranch && (
                          <div className="invalid-feedback">
                            {errors.bankBranch}
                          </div>
                        )}
                      </div>
                    </div>
                    <Button type="submit" variant="primary" disabled={loading}>
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                    <Button
                      variant="secondary"
                      className="ms-2"
                      onClick={() => {
                        setBankName("");
                        setIfscCode("");
                        setAccountNumber("");
                        setAccountHolderName("");
                        setAccountType("");
                        setBankBranch("");
                        setErrors({});
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>
              </Col>
            </Row>
          </div>
        </section>
        <section className="history">
          <div className="d-flex gap-2"></div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Bank Name</th>
                  <th>IFSC Code</th>
                  <th>Account Number</th>
                  <th>Account Holder Name</th>
                  <th>Account Type</th>
                  <th>Bank Branch</th>
                  {/* <th>Delete Bank</th>
                  <th>Set Default</th> */}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(bankData) &&
                  bankData?.map((x, i) => (
                    <tr key={i}>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">{x?.bankName}</td>
                      <td className="text-center">{x?.ifsc}</td>
                      <td className="text-center">{x?.accountNumber}</td>
                      <td className="text-center">{x?.holder}</td>
                      <td className="text-center">{x?.ac_type}</td>
                      <td className="text-center">{x?.branch}</td>
                      {/* <td className="text-center">
                        <button
                          className="block-btn"
                          onClick={() => handleDelete(x?.accountNumber)}
                        >
                          Delete Bank
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className={`block-btn ${
                            defaultBankId === x?.bankId ? "default-btn" : ""
                          }`}
                          onClick={() => handleDefault(x?.accountNumber)}
                          disabled={defaultBankId === x?.accountNumber}
                        >
                          {defaultBankId === x?.accountNumber
                            ? "Default"
                            : "Set Default"}
                        </button>
                      </td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </section>
  );
};

export default BankDetails;
