import { createSlice } from "@reduxjs/toolkit";

export const profileDataSlice = createSlice({
  name: "dashboardData", // Corrected the name to match with the reducer key
  initialState: {
    userPersonalInfo: [],
    mainWallet: "",
    fundWallet: "",
  },
  reducers: {
    setUserPersonalInfo: (state, action) => {
      state.userPersonalInfo = action.payload; // Corrected field name to dashboardProviders
    },
    setMainWallet: (state, action) => {
      state.mainWallet = action.payload; // Corrected field name to dashboardProviders
    },
    setFundWallet: (state, action) => {
      state.fundWallet = action.payload; // Corrected field name to dashboardProviders
    },
  },
});

export const { setUserPersonalInfo, setMainWallet, setFundWallet } =
  profileDataSlice.actions;
export default profileDataSlice.reducer;
