import React from "react";
import "./BalanceContainer.css";
// import Purse from "./../../Assets/Images/wallet.png";
import Purse from "./../../Assets/animatedIcons/wallet1.gif";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const BalanceContainer = () => {
  const navigate = useNavigate();
  const mainWalletBalance = useSelector(
    (state) => state.profileData.mainWallet
  );
  const fundWalletBalance = useSelector(
    (state) => state.profileData.fundWallet
  );
  return (
    <div className="gameBalanceContainer ">
      <div className="flex-center">
        {/* <div className="gameBalanceContainerAmount">
          <div className="d-flex align-items-center gap-2">
            <img src={Purse} alt="" />
            <div>
              <p style={{ fontFamily: "math", fontSize: "18px" }}>
                ₹ {mainWalletBalance}
              </p>
              <p className="m-0" style={{ fontSize: "12px" }}>
                Main Wallet
              </p>
            </div>
          </div>
        </div> */}
        <div className="gameBalanceContainerAmount">
          <div className="d-flex align-items-center gap-2">
            <img src={Purse} alt="" />
            <div>
              <p style={{ fontFamily: "math", fontSize: "18px" }}>
                ₹ {parseFloat(fundWalletBalance + mainWalletBalance).toFixed(2)}
              </p>
              <p className="m-0" style={{ fontSize: "12px" }}>
                Fund Wallet
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex-center gap-2">
        <button className="btnPrimary">Recharge</button>
        <button className="btnPrimary">Withdraw</button>
      </div> */}
      <button
        className="btnPrimary p-2"
        onClick={() => navigate("/fund-convert")}
      >
        Convert Balance
      </button>
    </div>
  );
};

export default BalanceContainer;
