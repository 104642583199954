// src/components/SomeComponent.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SomeComponent = () => {
  const config = useSelector((state) => state.config.config);
  const status = useSelector((state) => state.config.status);
  const error = useSelector((state) => state.config.error);

  if (status === 'loading') {
    return <div style={{color:"white"}}>Loading configuration...</div>;
  }

  if (status === 'failed') {
    return <div style={{color:"white"}}>Error: {error}</div>;
  }

  return (
    <div >
      <h1 style={{color:"white"}}>Configuration Loaded</h1>
      <pre style={{color:"white"}}>{JSON.stringify(config, null, 2)}</pre>
      {/* Example usage of the configuration values */}
      <p style={{color:"white"}}>Payment Method: {config?.paymentMethod}</p>
      <img src={config?.logo} alt="Logo" />
      <Link to='/test'>go</Link>
    </div>
  );
};

export default SomeComponent;
