import React, { useEffect, useState } from 'react';
import './GamesPlayer.css';
import { useLocation } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';

const GamesPlayer = () => {
    const [loading, setLoading] = useState(true);
    const [url, setUrl] = useState('');

    const location = useLocation();
    const { state } = location;
    const { url: gameUrl } = state;

    useEffect(() => {
        if (gameUrl) {
            setUrl(gameUrl);
            setLoading(true); // Start loading when new URL is set
        }
    }, [gameUrl]);

    const handleIframeLoad = () => {
        setLoading(false); // Set loading to false when iframe content has loaded
    };

    return (
        <div className="gamesPlayer">
            {loading &&
                <Loader />
            }
            <iframe
                src={url}
                frameBorder="0"
                className="iframe"
                onLoad={handleIframeLoad} // Handle iframe load event
            ></iframe>
        </div>
    );
}

export default GamesPlayer;
