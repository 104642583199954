import React, { useState } from "react";
import "./ColorPredictionGame.css"; // Add your CSS styles

const ColorPredictionGame = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);

  // Function to handle the click on a color/number
  const handleColorClick = (color) => {
    setSelectedColor(color);
    setShowPopup(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="game-container">
      <div className="color-options">
        {/* Replace these with your actual numbers/colors */}
        {["Red", "Green", "Blue", "Yellow"].map((color) => (
          <button
            key={color}
            className={`color-button ${color}`}
            onClick={() => handleColorClick(color)}
          >
            {color}
          </button>
        ))}
      </div>

      {/* Popup component */}
      {/* {showPopup && <Popup color={selectedColor} onClose={closePopup} />} */}
    </div>
  );
};

export default ColorPredictionGame;
