import React from "react";
import "./ProfilePage.css";
import Dashboard from "./../../Pages/Dashboard/Dashboard";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import Footer from "../../Components/Footer/Footer";
const ProfilePage = () => {
  return (
    <>
      <section className="mainContent">
        <Dashboard />
      </section>
      <Footer />
    </>
  );
};

export default ProfilePage;
