import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "../Redux/StatusState";
import { useDispatch } from "react-redux";
const AdminLogin = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (token && token.length > 10) {
          const response = await axios.get(
            `https://apis.win2o.com/user/get_profile`,
            {
              headers: { Authorization: token },
            }
          );
          if (response.status == 200) {
            localStorage.setItem("token", token);
            console.log("response1111111111111111111111111111", response);
            console.log("response?.data?.uid", response?.data?.uid);
            dispatch(setAuthToken(token));
            navigate("/");
          }
        }
      } catch (err) {
        console.log("errffffffffffffffffffffff", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);
  return <div>AdminLogin</div>;
};

export default AdminLogin;
