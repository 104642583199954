import { createSlice } from '@reduxjs/toolkit';

export const dashboardDataSlice = createSlice({
  name: 'dashboardData', // Corrected the name to match with the reducer key
  initialState: {
    dashboardProviders: [],
    firstGameSection: [],
    secondGameSection: [],
    thirdGameSection: [],
    topGames: [],
    // Add more status fields as needed
  },
  reducers: {
    setDashboardProviders: (state, action) => {
      state.dashboardProviders = action.payload; // Corrected field name to dashboardProviders
    },
    setFirstGameSection: (state, action) => {
      state.firstGameSection = action.payload; // Corrected field name to dashboardProviders
    },
    setSecondGameSection: (state, action) => {
      state.secondGameSection = action.payload; // Corrected field name to dashboardProviders
    },
    setThirdGameSection: (state, action) => {
      state.thirdGameSection = action.payload; // Corrected field name to dashboardProviders
    },
    setTopGames: (state, action) => {
      state.topGames = action.payload; // Corrected field name to dashboardProviders
    },
    // Add more reducers for other status fields
  },
});

export const { setDashboardProviders, setFirstGameSection, setSecondGameSection, setThirdGameSection, setTopGames } = dashboardDataSlice.actions;
export default dashboardDataSlice.reducer;
