import React, { useEffect, useState } from "react";
import "./Incomes.css";
import { MdOutlineFilterList } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { ApiPaths } from "./../../Config/ApiPaths";
import Loader from "../../Components/Loader/Loader";
import useAxiosHelper from "./../../Common/AxiosHalper";
import { setIncomeWallet } from "../../Redux/IncomeWallet";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "../../Components/PaginationControls/PaginationControls";
import { Link, useNavigate } from "react-router-dom";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import Transaction from "./../../Assets/Images/transaction.png";
import Withdraw from "./../../Assets/Images/withdrawal.png";
import Deposit from "./../../Assets/Images/deposit-icon.png";
import wallet from "./../../Assets/Images/wallet.png";
import ArrayToObject from "../../Common/ArrayToObject";
import LevelIncome from "../LevelIncome/LevelIncome";
import { BasicInfo } from "../../Config/BasicInfo";

const Incomes = () => {
  const navigate = useNavigate();
  const { AxiosGet } = useAxiosHelper();
  const dispatch = useDispatch();
  const [selectIncome, setSelectIncome] = useState("");
  const [filterVisiblity, setFilterVisiblity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [initValue, setInitValue] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNum, setPageNum] = useState();
  const [data, setData] = useState([]);
  const [incomeLiveRate, setIncomeLiveRate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentTransaction, setPaymentTransaction] = useState();
  const incomeData = useSelector((state) => state.incomeData.incomeWallet);
  const [amount, setAmount] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [paymentShow, setPaymentShow] = useState(false);

  const incomeWallets = incomeData.filter(
    (wallet) => wallet.wallet_type === "income"
  );

  useEffect(() => {
    fetchIncome();
  }, []);

  const handleClick = (slug) => {
    navigate("/level-income", { state: { slug } });
  };

  async function fetchIncome() {
    const res = await AxiosGet(ApiPaths.getWallets);
    if (res) {
      dispatch(setIncomeWallet(res?.wallets));
      const objectToArray = ArrayToObject(res?.wallets);
      setAmount(objectToArray?.main_wallet?.value);
    }
  }

  return (
    <>
      <section className="mainContent">
        {loading && <Loader />}
        <section className="incomeBox">
          <div style={{ position: "relative" }}>
            <div className="balanceBox">
              <div>
                <h1 className="heading">Incomes</h1>
                <div
                  className="wallet-container"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {/* {/ <img src={wallet} alt="Wallet Base" className="wallet-base" /> /} */}
                  {/* <img
                    src={wallet}
                    alt="Wallet Flap"
                    className={`wallet-flap ${isOpen ? "open" : "close"}`}
                  /> */}
                </div>
              </div>

              <div className="balanceShow">
                <h1>{BasicInfo.currency} {parseFloat(amount).toFixed(2) || "0"}</h1>
                <h3>Main Wallet</h3>
              </div>
            </div>
            <div className="incomeReport">
              <Row>
                <Col lg="4" md="6" xs="12">
                  <Link to="/withdrawal">
                    <div className="dashboardIncomeCard">
                      <div className="incomeIcons">
                        <i>
                          {" "}
                          <img src={Transaction} width="80px" height="80px" />
                        </i>
                      </div>

                      <div>
                        <h4>New Withdrawl</h4>
                        {/* <p>
                      Send Withdrawl Request
                    </p> */}
                      </div>
                    </div>
                  </Link>{" "}
                </Col>
                <Col lg="4" md="6" xs="12">
                  <Link to="/history-deposit">
                    <div className="dashboardIncomeCard">
                      <div>
                        <i>
                          {" "}
                          <img src={Deposit} width="80px" height="80px" />
                        </i>
                      </div>
                      <div>
                        <h4>Deposit</h4>
                        {/* {/ <p>Send Deposit Request</p> /} */}
                      </div>
                    </div>
                  </Link>{" "}
                </Col>
                <Col lg="4" md="6" xs="12">
                  <Link to="/history-withdraw">
                    <div className="dashboardIncomeCard">
                      <div>
                        <i>
                          {" "}
                          <img src={Withdraw} width="80px" height="80px" />
                        </i>
                      </div>
                      <div>
                        <h4>Withdrawal</h4>
                        {/* {/ <p>Send Deposit Request</p> /} */}
                      </div>
                    </div>
                  </Link>{" "}
                </Col>

                <Col lg="4" md="6" xs="12">
                  <Link to="/bets">
                    <div className="dashboardIncomeCard">
                      <div>
                        <i>
                          <img src={Deposit} width="80px" height="80px" />
                        </i>
                      </div>
                      <div>
                        <h4>Bids</h4>
                        {/* {/ <p>SLevel Income History</p> /} */}
                      </div>
                    </div>
                  </Link>{" "}
                </Col>
                {incomeWallets?.map((x, i) => {
                  return (
                    <Col lg="4" md="6" xs="12">
                      <div
                        className="dashboardIncomeCard"
                        onClick={() => handleClick(x?.slug)}
                      >
                        <div>
                          <i>
                            <img src={Deposit} width="80px" height="80px" />
                          </i>
                        </div>
                        <div>
                          <h4>{x?.name}</h4>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="incomeShowBox">
          <Row md="12">
            <Col
              lg="12"
              className="gap-2 d-flex flex-column justify-content-between"
            >
              <Row>
                {incomeData?.map((x, i) => {
                  return (
                    x?.wallet_type == "income" && (
                      <Col md="3" className="mb-2">
                        <div className="dashboardIncomeCard">
                          <div className="dashboardData">
                            <div>
                              <h5
                                className="dashboardCardHeading"
                                style={{ textTransform: "capitalize" }}
                              >
                                {x?.name}
                              </h5>
                              <h1>
                                ₹ {parseFloat(x?.value).toFixed(2) ?? "0"}
                              </h1>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  );
                })}
              </Row>
            </Col>
          </Row>
          </div>
          
          </section> */}
      </section>
    </>
  );
};

export default Incomes;
