import React, { useEffect, useState } from "react";
import { ApiPaths } from "../../Config/ApiPaths";
import useAxiosHelper from "../../Common/AxiosHalper";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setFundWallet, setMainWallet } from "../../Redux/ProfileDataSlice";
import { AiOutlineSwapRight } from "react-icons/ai";
const FundConvert = () => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [userId, setUserId] = useState("");
  const [amountError, setAmountError] = useState("");
  const { AxiosPost, AxiosGet } = useAxiosHelper();
  const dispatch = useDispatch();
  const mainWalletBalance = useSelector(
    (state) => state.profileData.mainWallet
  );
  const fundWalletBalance = useSelector(
    (state) => state.profileData.fundWallet
  );

  useEffect(() => {
    FetchData();
  }, []);

  async function FetchData() {
    try {
      const tempData = await AxiosGet(ApiPaths.getWallets);
      console.log("tempData", tempData);
      tempData?.wallets?.map((x, i) => {
        if (x?.slug == "fund_wallet") {
          dispatch(setFundWallet(x?.value));
        }
        if (x?.slug == "main_wallet") {
          dispatch(setMainWallet(x?.value));
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function FundTransferFunc() {
    setAmountError("");
    if (!amount > 0 || amount > mainWalletBalance) {
      setAmountError("Invalid Amount");
    }
    const body = {
      username: userId,
      amount: amount,
      type: "self",
    };
    console.log("body", body);
    if (amount > 0 && amount <= mainWalletBalance) {
      try {
        setLoading(true);
        const response = await AxiosPost(ApiPaths.p2pTransfer, body);
        toastSuccess(response?.message);
        FetchData();
        setAmount("");
        console.log("response", response);
        setLoading(false);
      } catch (e) {
        toastFailed(e?.response?.data?.message);
        console.log("error", e);
        setLoading(false);
      }
    }
  }

  return (
    <section className="mainContent ">
      {loading ? <Loader /> : null}
      <div className="addfundDiv inputPrimary withdrawalPage">
        <h1
          style={{
            fontSize: "20px",
            textAlign: "center",
            color: "var(--textHeading)",
          }}
        >
          Fund Convert
        </h1>
        <div className="flex-center">
          <div className="addfundDivFundWallet ">
            <p>
              {BasicInfo?.currency}
              {parseFloat(mainWalletBalance).toFixed(2)}
            </p>
            <p>Main Wallet</p>
          </div>
          <i style={{ color: "var(--textHeading)", fontSize: "30px" }}>
            <AiOutlineSwapRight />
          </i>
          <div className="addfundDivFundWallet ">
            <p>
              {BasicInfo?.currency}
              {parseFloat(fundWalletBalance).toFixed(2)}
            </p>
            <p>Fund Wallet</p>
          </div>
        </div>

        <label htmlFor="Amount">Amount</label>
        <input
          className="mainInput"
          min={1}
          required
          type="number"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <p className="errorMsg">{amountError}</p>
        <button className="btnPrimary mt-3" onClick={FundTransferFunc}>
          Convert
        </button>
      </div>
      {/* <section>
        <div>
          {status && <FundTransferHistory key={change} status={status} />}
        </div>
      </section> */}
    </section>
  );
};

export default FundConvert;
