import React, { useEffect, useState } from "react";
import "./Register.css";
import { RxCross2 } from "react-icons/rx";
import Gift from "./../../Assets/Images/gift.png";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import BannerImage from "./../../Assets/Images/registerImg.jpg";
import { useDispatch } from "react-redux";
import { setRegisterDisplay } from "../../Redux/RegisterSlice";
import { setLoginDisplay } from "../../Redux/LoginSlice";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import RotateLoader from "../../Components/RotateLoader/RotateLoader";
import { ApiPaths } from "../../Config/ApiPaths";
import useAxiosHelper from "../../Common/AxiosHalper";
import OtpInput from "react-otp-input";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { ImCancelCircle } from "react-icons/im";
import axios from "axios";
import { setAuthToken } from "../../Redux/StatusState";

const Register = () => {
  const { AxiosPost } = useAxiosHelper();
  const dispatch = useDispatch();
  const [passwordVisiblity, setPasswordVisiblity] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const [registerData, setRegisterData] = useState([]);
  const [formSponsorError, setFormSponsorError] = useState("");
  const [formName, setFormName] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formMobile, setFormMobile] = useState("");
  const [formNameError, setFormNameError] = useState("");
  const [formPassword, setFormPassword] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [formMobileError, setFormMobileError] = useState("");
  const [formPasswordError, setFormPasswordError] = useState("");
  const [formPromoCode, setFormPromoCode] = useState("");
  const [confirmTerms, setConfirmTerms] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sponsor, setSponsor] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ref = params.get("ref");
    console.log("ref", ref);
    if (ref) {
      setSponsor(ref);
    }
  }, []);

  function ChnageFun() {
    dispatch(setRegisterDisplay(false));
    dispatch(setLoginDisplay(true));
  }

  async function SendOTP(username) {
    try {
      const body = {
        action: "verify_account",
        username: username,
      };
      const tempData = await AxiosPost(ApiPaths.sendOTP, body);
      console.log(tempData);
      toastSuccess("OTP sent successfully");
      setOtpPopup(true);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async function RegisterFun() {
    const isFormValid = await checkFormValid();
    if (isFormValid == true) {
      setIsLoading(true);
      try {
        const body = {
          name: formName,
          email: formEmail,
          mobile: formMobile,
          password: formPassword,
          sponsor: sponsor,
        };
        const res = await AxiosPost(ApiPaths.register, body);
        BasicInfo.isDebug && console.log("register", res);
        if (res.status == 200) {
          // dispatch(setRegisterDisplay(false));
          console.log("res?.User?.username", res?.User?.username);
          // toastSuccess("Please verify your account");
          setRegisterData(res);
          const tempOtpRes = await SendOTP(res?.User?.username);
          setIsLoading(false);
          // localStorage.setItem("token", res?.token);
          // dispatch(setAuthToken(res?.token));
          // localStorage.setItem("userProfile", JSON.stringify(res?.User));
        }
      } catch (error) {
        console.error(error);
        toastFailed(error?.response?.data?.message);
        setIsLoading(false);
      }
    }
  }

  async function checkFormValid() {
    setFormNameError("");
    setFormEmailError("");
    setFormMobileError("");
    setFormPasswordError("");

    let x = 0;
    if (formName == null || formName.length == 0) {
      setFormNameError("Name is required");
    } else {
      x++;
    }
    if (formEmail == null || formEmail.length == 0) {
      setFormEmailError("Email is required");
    } else {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailVarify = regex.test(formEmail);
      if (emailVarify == true) {
        x++;
      } else {
        setFormEmail("E-mail must be valid");
      }
    }
    if (formMobile == null || formMobile.length == 0) {
      setFormMobileError("Phone number must be valid");
    } else if (!isValidPhoneNumber(formMobile)) {
      setFormMobileError("Invalid phone number");
    } else {
      x++;
    }

    if (formPassword.length >= 6) {
      x++;
    } else {
      setFormPasswordError("min 6 characters");
    }

    if (sponsor.length > 0) {
      x++;
    } else {
      setFormSponsorError("Sponsor cannot be empty");
    }

    if (x == 5) {
      return true;
    } else return false;
  }

  async function VerifyOTP() {
    const headers = {
      authorization: registerData?.token,
    };

    const body = {
      otp: otp,
      action: "verify_account",
    };

    try {
      setOtpLoading(true);
      const response = await axios.post(
        `https://apis.win2o.com/user/verify-account`,
        body,
        {
          headers,
        }
      );
      console.log("Response:", response.data);
      toastSuccess(response?.data?.message);
      dispatch(setRegisterDisplay(false));
      setOtpPopup(false);
      localStorage.setItem("token", registerData?.token);
      dispatch(setAuthToken(registerData?.token));
      localStorage.setItem("userProfile", JSON.stringify(registerData?.User));
      setOtpLoading(false);
    } catch (error) {
      setOtpLoading(false);
      toastFailed(error?.response?.data?.message);
      if (BasicInfo.isDebug) {
        console.error("Error verifying OTP:", error);
      }
    }
  }

  return (
    <section className="registerSection">
      {otpPopup && (
        <div className="verifyAccountPopup">
          <div>
            <i onClick={() => setOtpPopup(false)}>
              <ImCancelCircle />
            </i>
            <h5>Please enter your OTP to verify</h5>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            {otpLoading ? (
              <RotateLoader />
            ) : (
              <button
                className="btnPrimary mt-4"
                onClick={VerifyOTP}
                style={{ fontSize: "14px" }}
              >
                Verify
              </button>
            )}
          </div>
        </div>
      )}
      <div className="registerData">
        <div className="registerLeft">
          <img src={BannerImage} alt="banner" />
          <div className="registerLeftContent">
            <h1>SIGN UP</h1>
            <p>
              Step into the future of gaming with our innovative range of
              action-packed prediction and strategy games.
            </p>
            <img src={BasicInfo.logo} alt="Logo" />
          </div>
        </div>
        <div className="registerRight">
          <div style={{ position: "relative" }}>
            <i
              className="d-flex align-items-center justify-content-end mb-2"
              id="registerCrossIcon"
              onClick={() => dispatch(setRegisterDisplay(false))}
            >
              <RxCross2 />
            </i>
            <div id="registerGiftDiv " className="mb-3 mt-4">
              <div id="registerGiftDivIcon">
                <div>
                  <img src={Gift} alt="gift" />
                </div>
                <p>
                  Bonus ON FIRST <br /> DEPOSIT
                </p>
              </div>
            </div>

            <input
              type="text"
              placeholder="Sponsor"
              className="mainInput"
              value={sponsor}
              onChange={(e) => setSponsor(e.target.value)}
            />
            <p className="formError">{formSponsorError}</p>
            <input
              type="text"
              placeholder="Name"
              className="mainInput mt-12"
              value={formName}
              onChange={(e) => setFormName(e.target.value)}
            />
            <p className="formError">{formNameError}</p>
            <input
              type="text"
              placeholder="Email"
              className="mainInput mt-12"
              value={formEmail}
              onChange={(e) => setFormEmail(e.target.value)}
            />
            <p className="formError">{formEmailError}</p>
            <div id="registerMobileDiv" className="mt-12">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="IN"
                value={formMobile}
                onChange={setFormMobile}
                error={
                  formMobile
                    ? isValidPhoneNumber(formMobile)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
              />

              {/* <input type="text" placeholder='Phone number' className='mainInput' value={formMobile} onChange={(e) => setFormMobile(e.target.value)} /> */}
            </div>
            <p className="formError">{formMobileError}</p>
            <div id="registerPasswordDiv" className="  mt-12">
              <input
                type={passwordVisiblity == true ? "text" : "password"}
                placeholder="Password"
                className="mainInput"
                value={formPassword}
                onChange={(e) => setFormPassword(e.target.value)}
              />
              <i onClick={() => setPasswordVisiblity(!passwordVisiblity)}>
                {passwordVisiblity == true ? <IoEyeSharp /> : <IoEyeOffSharp />}
              </i>
            </div>
            <p className="formError">{formPasswordError}</p>
            {/* {havePromoCode == true ? (
              <div className="registerApplyCode mt-12">
                <input
                  type="text"
                  placeholder="Add promo code here"
                  className="mainInput"
                  value={formPromoCode}
                  onChange={(e) => setFormPromoCode(e.target.value)}
                />
                <button
                  style={{
                    background: formPromoCode
                      ? "#4992DB"
                      : "rgba(255, 255, 255, .12)",
                  }}
                >
                  Apply
                </button>
              </div>
            ) : (
              <div
                id="registerPromoCode"
                className="mt-12"
                onClick={() => setHavePromoCode(true)}
              >
                <i>
                  <HiPlusSm />
                </i>
                <p>I have a promo code</p>
              </div>
            )}
            <p className="modal__form-title">Choose your bonus</p>
            <Select
              isClearable={true}
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={customStyles} // Apply custom styles here
            /> */}
            <div className="mb-4"></div>
            {isLoading == true ? (
              <RotateLoader />
            ) : (
              <button
                className="btnPrimary"
                onClick={RegisterFun}
                style={{ fontSize: "14px" }}
              >
                SIGN UP
              </button>
            )}

            <label
              for="topping1"
              className="registerTermCondition"
              style={{ color: confirmTerms != true && "red" }}
            >
              <input
                type="checkbox"
                id="topping1"
                checked={confirmTerms}
                onChange={(e) => setConfirmTerms(e.target.checked)}
              />
              i confirm all the{" "}
              <a href="#" style={{ color: confirmTerms != true && "red" }}>
                Terms of user agreement
              </a>{" "}
              and that i am over 18
            </label>
            {/* <img src={Adult} alt="18+" id="adult" /> */}

            <div className="alreadyRegister">
              <p>Already registered?</p>
              <Link onClick={ChnageFun}>Sign in</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
