import React, { useEffect, useState } from "react";
import "./Profile.css";
import { Col, Row } from "react-bootstrap";
import { MdOutlineModeEdit } from "react-icons/md";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import { useSelector, useDispatch } from "react-redux";
import "./../../Common.css";
import {IoEye, IoEyeOff } from "react-icons/io5";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import RotateLoader from "../RotateLoader/RotateLoader";
import { setUserPersonalInfo } from "../../Redux/ProfileDataSlice";

const Profile = () => {
  const dispatch = useDispatch();
  const { AxiosGet, AxiosPost } = useAxiosHelper();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const profileData = useSelector((state) => state.profileData.userPersonalInfo);
  const [loading, setLoading] = useState(false);

  // Fetch profile data when the component is mounted
  useEffect(() => {
    FetchData();
  }, []);

  // Update name and email states when profile data changes
  useEffect(() => {
    if (profileData) {
      setName(profileData.name);
      setEmail(profileData.email);
    }
  }, [profileData]);

  // Fetch user profile data from the server
  const FetchData = async () => {
    if (!profileData || profileData?.length === 0) {
      try {
        setLoading(true);
        const tempProfileData = await AxiosGet(ApiPaths.getProfile);
        BasicInfo.isDebug && console.log("tempProfileData", tempProfileData);
        dispatch(setUserPersonalInfo(tempProfileData));
      } catch (e) {
        console.error("Error fetching profile data", e);
      } finally {
        setLoading(false);
      }
    }
  };

  // Handle updating the profile with new name and email
  const updateProfile = async () => {
    return 0;
    try {
      setLoading(true);
      const updatedData = { name, email };
      const response = await AxiosPost(ApiPaths.updateProfile, updatedData);
      toastSuccess(response?.message);
      dispatch(setUserPersonalInfo({ ...profileData, name, email }));
    } catch (e) {
      console.error("Error updating profile", e);
      toastFailed(e?.response?.data?.message || "Failed to update profile");
    } finally {
      setLoading(false);
    }
  };

  // Validate password change inputs
  const checkPasswordDataIsValid = () => {
    if (
      oldPassword?.length > 0 &&
      newPassword?.length > 0 &&
      confirmPassword?.length > 0
    ) {
      if (newPassword === confirmPassword) {
        return true;
      } else {
        toastFailed("New password and confirm password should be the same");
        return false;
      }
    } else {
      toastFailed("Invalid Data");
      return false;
    }
  };

  // Handle password change request
  const ChangePassword = async () => {
    let valid = checkPasswordDataIsValid();
    if (valid) {
      setPasswordLoading(true);
      try {
        const formData = {
          currentPassword: oldPassword,
          newPassword: newPassword,
        };
        const response = await AxiosPost(ApiPaths.changePassword, formData);
        toastSuccess(response?.message);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } catch (e) {
        console.error("Error changing password", e);
        toastFailed(e?.response?.data?.message || "Failed to change password");
      } finally {
        setPasswordLoading(false);
      }
    }
  };

  return (
    <section className="mainContent">
      <section className="myProfile">
        <Row>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Name</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <i id="myProfileInputFieldIcon">
                <MdOutlineModeEdit />
              </i>
            </div>
          </Col>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Username</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                placeholder="username"
                value={profileData?.username}
                readOnly={true}
              />
            </div>
          </Col>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">E-mail</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <i id="myProfileInputFieldIcon">
                <MdOutlineModeEdit />
              </i>
            </div>
          </Col>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Phone Number</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                placeholder="12345-67890"
                value={profileData?.mobile}
                readOnly={true}
              />
            </div>
          </Col>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Sponsor Name</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                placeholder="abc@gmail.com"
                value={profileData?.sponsor_Name}
                readOnly={true}
              />
            </div>
          </Col>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Joining Date</span>
              <input
                id="myProfileInputFieldInput"
                type="text"
                placeholder="joining date"
                value={profileData?.joining_date}
                readOnly={true}
              />
            </div>
          </Col>
        </Row>
        {loading ? (
          <button className="btnPrimaryMedium" style={{ cursor: "progress" }}>
            <RotateLoader />
          </button>
        ) : (
          <button className="btnPrimaryMedium" onClick={updateProfile}>
            Update Data
          </button>
        )}

        <h3 style={{ color: "var(--textHeading)", fontWeight: "600" }}>
          Change Password
        </h3>
        <hr style={{ color: "var(--textLight)", margin: "20px 0 60px 0" }} />

        <Row>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Old Password</span>
              <input
                id="myProfileInputFieldInput"
                type={oldPasswordType}
                placeholder="Enter Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              {oldPasswordType === "password" ? (
                <i
                  id="myProfileInputFieldIcon"
                  onClick={() => setOldPasswordType("text")}
                >
                  <IoEyeOff />
                </i>
              ) : (
                <i
                  id="myProfileInputFieldIcon"
                  onClick={() => setOldPasswordType("password")}
                >
                  <IoEye />
                </i>
              )}
            </div>
          </Col>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">New Password</span>
              <input
                id="myProfileInputFieldInput"
                type={newPasswordType}
                placeholder="Enter New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {newPasswordType === "password" ? (
                <i
                  id="myProfileInputFieldIcon"
                  onClick={() => setNewPasswordType("text")}
                >
                  <IoEyeOff />
                </i>
              ) : (
                <i
                  id="myProfileInputFieldIcon"
                  onClick={() => setNewPasswordType("password")}
                >
                  <IoEye />
                </i>
              )}
            </div>
          </Col>
          <Col md="4">
            <div className="myProfileInputField">
              <span id="myProfileInputFieldTitle">Confirm Password</span>
              <input
                id="myProfileInputFieldInput"
                type={confirmPasswordType}
                placeholder="Enter Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {confirmPasswordType === "password" ? (
                <i
                  id="myProfileInputFieldIcon"
                  onClick={() => setConfirmPasswordType("text")}
                >
                  <IoEyeOff />
                </i>
              ) : (
                <i
                  id="myProfileInputFieldIcon"
                  onClick={() => setConfirmPasswordType("password")}
                >
                  <IoEye />
                </i>
              )}
            </div>
          </Col>
        </Row>
        {passwordLoading ? (
          <button className="btnPrimaryMedium" style={{ cursor: "progress" }}>
            <RotateLoader />
          </button>
        ) : (
          <button className="btnPrimaryMedium" onClick={ChangePassword}>
            Update Password
          </button>
        )}
      </section>
    </section>
  );
};

export default Profile;
