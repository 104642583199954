import React from "react";
import "./ProfileHeader.css";
import { TbListDetails } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
const ProfileHeader = () => {
  const navigate = useNavigate();
  return (
    <div className="profileHeader pb-2">
      {/* <div id="profileHeaderHeading">
        <i>
          <TbListDetails />
        </i>
        <h1>Personal Data</h1>
      </div> */}
    </div>
  );
};

export default ProfileHeader;
