import React, { useEffect, useState } from "react";
import "./Home.css";
import Banner from "../../Components/Banner/Banner";
import Dashboard from "./../Dashboard/Dashboard";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../../Components/Footer/Footer";
import Game1 from "./../../Assets/Images/game1.png";
import Game2 from "./../../Assets/Images/game2.png";
import Game3 from "./../../Assets/Images/game3.png";
import Game4 from "./../../Assets/Images/game4.png";
import Game5 from "./../../Assets/Images/game5.png";
import Game6 from "./../../Assets/Images/game6.png";
import Game7 from "./../../Assets/Images/game7.png";
import Game8 from "./../../Assets/Images/game8.png";
import Game9 from "./../../Assets/Images/game9.png";
import Game10 from "./../../Assets/Images/game10.png";
import Game11 from "./../../Assets/Images/game11.png";
import Game12 from "./../../Assets/Images/game12.png";
import DragonTiger from "./../../Assets/Images/dragontiger.jpg";
import ColorPre from "./../../Assets/Images/colorpre.webp";
// import lottery from "./../../Assets/Images/lottery.png";
// import Aviatorr from "./../../Assets/Images/Aviatorr.png";
// import spin from "./../../Assets/Images/spin.png";
// import trophy from "./../../Assets/Images/trophy.png";
// import mini from "./../../Assets/Images/mini.png";
// import fishing from "./../../Assets/Images/fishing.png";
// import pvc from "./../../Assets/Images/pvc.png";
// import casino from "./../../Assets/Images/caino.png";
import { BasicInfo, toastInfo } from "../../Config/BasicInfo";
import Refer from "../../Components/Refer/Refer";
import { useDispatch, useSelector } from "react-redux";
import { ApiPaths } from "../../Config/ApiPaths";
import useAxiosHelper from "../../Common/AxiosHalper";
import { setIncomeWallet } from "./../../Redux/IncomeWallet";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const authToken = useSelector((state) => state.userStatus.authToken);
  const Games = [
    { img: DragonTiger },
    { img: ColorPre, url: "number-game" },
    { img: Game1 },
    { img: Game2 },
    { img: Game3 },
    { img: Game4 },
    { img: Game5 },
    { img: Game6 },
    { img: Game7 },
    { img: Game8 },
    { img: Game9 },
    { img: Game10 },
    { img: Game11 },
    { img: Game12 },
  ];
  const navigate = useNavigate();
  const incomeData = useSelector((state) => state.incomeData.incomeWallet);
  const [teamData, setTeamData] = useState("");
  const { AxiosGet } = useAxiosHelper();
  const dispatch = useDispatch();

  async function fetchTeam() {
    try {
      const res = await AxiosGet(ApiPaths.getTeamSection);
      console.log(res?.sum);
      setTeamData(res?.sum);
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchData() {
    try {
      const res = await AxiosGet(ApiPaths.getWallets);
      BasicInfo.isDebug && console.log(res);
      if (res) dispatch(setIncomeWallet(res?.wallets));
    } catch (error) {
      console.log(error);
    }
  }

  var x = 0;
  useEffect(() => {
    if (authToken) {
      fetchTeam();
      fetchData();
      x++;
    }
  }, [authToken]);
  return (
    <>
      <section className="homePage">
        <section className="homePageData container">
          <Container>
            <Banner />
            {/* <div className="providerGameSectionsGames mt-4">
            {allGames.map((x, i) => {
                return (
                  <div
                    className="allGameThumbnail"
                    onClick={() => toastInfo("Coming soon...")}
                  >
                    <img src={x.img} alt="" />
                  </div>
                );
              })}
              </div> */}
            <div className="providerGameSectionsGames mt-4">
              {Games.map((x, i) => {
                return (
                  <div
                    className="allGameThumbnail"
                    onClick={() =>
                      x?.url ? navigate(x?.url) : toastInfo("Coming soon...")
                    }
                  >
                    {x?.url ? (
                      <div id="gameLive">
                        <span></span>
                        Live
                      </div>
                    ) : (
                      <div id="gameComing">Coming soon</div>
                    )}
                    <img src={x.img} alt="" />
                  </div>
                );
              })}
            </div>
          </Container>
        </section>
        {authToken != null && (
          <>
            <section className="myProfile">
              <Row md="12">
                <Col
                  lg="12"
                  className="gap-2 d-flex flex-column justify-content-between"
                >
                  <Row>
                    <Col md="4" className="mb-2">
                      <div className="dashboardIncomeCard">
                        <div className="walletDiv">
                          <div className="iconHeading">
                            <p>Wallets</p>
                          </div>
                          {incomeData?.map((x, i) => {
                            return (
                              x?.wallet_type == "wallet" && (
                                <div className="walletData">
                                  <h5
                                    className="dashboardCardHeading"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {x?.name}
                                  </h5>
                                  <h1>
                                    {BasicInfo.currency}{" "}
                                    {parseFloat(x?.value).toFixed(2) ?? "0"}
                                  </h1>{" "}
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="mb-2">
                      <div className="dashboardIncomeCard">
                        <div className="walletDiv">
                          <div className="iconHeading">
                            <p>Teams</p>
                          </div>

                          <div className="walletData">
                            <h5
                              className="dashboardCardHeading"
                              style={{ textTransform: "capitalize" }}
                            >
                              Total Teams
                            </h5>
                            <h1>{teamData?.total_team}</h1>
                          </div>
                          <div className="walletData">
                            <h5
                              className="dashboardCardHeading"
                              style={{ textTransform: "capitalize" }}
                            >
                              Active Teams
                            </h5>
                            <h1>{teamData?.active_team}</h1>
                          </div>
                          <div className="walletData">
                            <h5
                              className="dashboardCardHeading"
                              style={{ textTransform: "capitalize" }}
                            >
                              Inactive Teams
                            </h5>
                            <h1>
                              {teamData?.total_team - teamData?.active_team}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md="4" className="mb-2">
                      <div className="dashboardIncomeCard">
                        <div className="walletDiv">
                          <div className="iconHeading">
                            <p>Business</p>
                          </div>

                          <div className="walletData">
                            <h5
                              className="dashboardCardHeading"
                              style={{ textTransform: "capitalize" }}
                            >
                              Business{" "}
                            </h5>
                            <h1>{teamData?.business}</h1>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </section>

            <Refer />
          </>
        )}
        <Footer />
      </section>
    </>
  );
};

export default Home;
