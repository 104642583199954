import React, { useEffect, useState } from "react";
import "./Withdrawal.css";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import { BasicInfo, toastFailed, toastSuccess } from "../../Config/BasicInfo";
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Axios } from "axios";

const Withdrawal = () => {
  const { AxiosPost, AxiosGet } = useAxiosHelper();

  const [formUpiId, setFormUpiId] = useState("");
  const [formAmount, setFormAmount] = useState("");
  const [upiIdError, setUpiIdError] = useState("");
  const [amountError, setAmountError] = useState("");
  const [bankData, setBankData] = useState([]);
  const [defaultBankId, setDefaultBankId] = useState(null);
  const [accNo, setAccNo] = useState("");
  const [loading, setLoading] = useState(false);
  const walletBalance = useSelector((state) => state.profileData.mainWallet);
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const options = [
    { value: "", label: "Select Type" },
    { value: "upi", label: "UPI" },
    { value: "bank", label: "BANK" },
    {
      value: "web3",
      label: "WEB3",
    },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--containerColor)",
      borderColor: "var(--containerColor)",
      padding: "4px",
      fontSize: "16px",
      borderRadius: "30px",
      marginBottom: "20px",
      color: "#fff",
    }),
    options: (provided, state) => ({
      color: "black",
    }),
  };
  const [selectedOption, setSelectedOption] = useState(options[1]);
  useEffect(() => {
    WithdrawData();
  }, [selectedOption]);
  const handleChange = (e) => {
    setAccNo(e.target.value);
    console.log(accNo, "accno");
  };
  useEffect(() => {
    if (bankData != null) {
      setAccNo(bankData[0]?.bank?.accountNumber);
    }
    if (bankData != null) {
      setFormUpiId(bankData[0]?.upi?.upiId);
    }
    if (bankData != null) {
      setAddress(bankData[0]?.web3?.address);
    }
  }, [bankData]);
  const WithdrawData = async () => {
    try {
      setLoading(true);
      const filters = {
        type: selectedOption?.value,
      };
      const queryParams = {
        // page: page,
        limit: 20,
        ...filters,
      };

      const tempWithdrawData = await AxiosGet(
        `${ApiPaths.getAccountDetails}?${new URLSearchParams(
          queryParams
        ).toString()}`
      );
      console.log("tempWithdrawData", tempWithdrawData);
      setBankData(tempWithdrawData?.data);
    } catch (error) {
      BasicInfo.isDebug &&
        console.error("Error fetching wallet details:", error);
      toastFailed(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  async function Withdraw() {
    try {
      const body = {
        paymentMethod: selectedOption?.value,
        amount: formAmount,
        account:
          selectedOption?.value == "bank"
            ? accNo
            : selectedOption?.value == "upi"
            ? formUpiId
            : selectedOption?.value == "web3"
            ? address
            : null,
      };
      console.log(body);

      const res = await AxiosPost(ApiPaths.withdraw, body);
      console.log(res);
      if (res) {
        toastSuccess(res?.message);
        setFormAmount("");
      }
    } catch (error) {
      console.log(error);
      toastFailed(error?.response?.data?.message);
    }
  }

  return (
    <section className="mainContent">
      {loading && <Loader />}
      <ProfileHeader />

      <section className="withdrawalPage">
        <div>
          <div>
            <label htmlFor="amount">Withdraw Options</label>

            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              styles={customStyles}
              id="betSelectBox"
            />
          </div>
          {selectedOption.value === "bank" ? (
            <div style={{ position: "relative" }}>
              <label htmlFor="upiId">Bank Account Number</label>
              <div style={{ marginBottom: "10px" }} className="incomeSelectBox">
                <select
                  style={{ width: "100%" }}
                  id="upiSelect"
                  value={accNo}
                  onChange={(e) => handleChange(e)}
                >
                  {bankData?.map((x, index) => (
                    <option key={index} value={x?.bank?.accountNumber}>
                      {x?.bank?.accountNumber}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : selectedOption.value === "upi" ? (
            <div style={{ position: "relative" }}>
              <label htmlFor="upiId">UPI ID</label>
              <div style={{ marginBottom: "10px" }} className="incomeSelectBox">
                <select
                  style={{ width: "100%" }}
                  id="upiSelect"
                  value={formUpiId}
                  onChange={(e) => setFormUpiId(e.target.value)}
                >
                  {bankData?.map((x, index) => (
                    <option key={index} value={x?.upi?.upiId}>
                      {x?.upi?.upiId}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              <label htmlFor="upiId">Web Address</label>
              <div style={{ marginBottom: "10px" }} className="incomeSelectBox">
                <select
                  style={{ width: "100%" }}
                  id="upiSelect"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                >
                  {bankData?.map((x, index) => (
                    <option key={index} value={x?.web3?.address}>
                      {x?.web3?.address}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}

          {/* 
            <div style={{ position: "absolute", top: "0px", right: "0px" }}>
              <Link style={{ fontSize: "12px" }} to="/account-details">
                Change
              </Link>
            </div> */}

          <label htmlFor="amount">Amount</label>
          <input
            id="amount"
            type="text"
            className="mainInput"
            placeholder="Enter Amount"
            value={formAmount}
            onChange={(e) => {
              setFormAmount(e.target.value);
            }}
            required
          />

          <button className="btnPrimary" onClick={Withdraw}>
            Withdraw
          </button>
        </div>
      </section>
    </section>
  );
};

export default Withdrawal;
