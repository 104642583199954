import React, { useEffect, useState } from "react";
import "./BidsHistory.css";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useAxiosHelper from "../../Common/AxiosHalper";
import { ApiPaths } from "../../Config/ApiPaths";
import PaginationComponent from "../../Components/PaginationControls/PaginationControls";
import Loader from "../../Components/Loader/Loader";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import moment from "moment/moment";
import Select from "react-select";
const BidsHistory = () => {
  const navigate = useNavigate();
  const { AxiosGet } = useAxiosHelper();
  const [tabsName, setTabsName] = useState("deposit");
  const [paymentTransaction, setPaymentTransaction] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [betType, setBetType] = useState("bet");
  const options = [
    { value: "bet", label: "All Bets" },
    { value: "win", label: "Win" },
  ];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "var(--containerColor)",
      borderColor: "var(--containerColor)",
      padding: "4px",
      fontSize: "16px",
      borderRadius: "30px",
      marginBottom: "20px",
      color: "#fff",
    }),
  };
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    const tabName = localStorage.getItem("historyTab");
    if (tabName) {
      setTabsName(tabName);
    }
  }, []);

  useEffect(() => {
    FetchData(currentPage);
  }, [tabsName, currentPage, selectedOption]);

  const FetchData = async (page) => {
    try {
      setLoading(true);
      const filters = {
        source: selectedOption.value,
      };
      const queryParams = {
        page: page,
        limit: 20,
        ...filters,
      };
      const response = await AxiosGet(
        `${ApiPaths.getPaymentTransaction}?${new URLSearchParams(
          queryParams
        ).toString()}`
      );

      setPaymentTransaction(response?.data || []);
      setTotalPages(response?.totalPages || 1);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching payment transactions:", error);
      setError("Failed to fetch data");
      setLoading(false);
    }
  };
  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <section className="mainContent">
      <section className="paymentHistoryBody">
        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options}
          styles={customStyles}
          id="betSelectBox"
        />
        <Row>
          {loading && <Loader />}

          {error && <p>Error: {error}</p>}
          {!loading && paymentTransaction.length === 0 && (
            <p>No data available</p>
          )}
          {betType == "win"
            ? paymentTransaction?.map((x, i) => (
                <Col md="6" lg="4" key={i} className="m-0 p-1">
                  <div className="depositHistoryCard">
                    <div id="depositHistoryCardTop">
                      <p>{moment(x?.time).format("LLL")}</p>
                      {x?.status === 0 ? (
                        <h5 style={{ background: "var(--blueColor)" }}>
                          Pending
                        </h5>
                      ) : x?.status === 1 ? (
                        <h5 style={{ background: "green" }}>Success</h5>
                      ) : (
                        <h5>Failed</h5>
                      )}
                    </div>
                    <h1 id="depositHistoryCardHeading">
                      {parseFloat(x?.amount).toFixed(2)} INR
                    </h1>
                    <div id="depositHistoryCardDetails">
                      <p>Transaction ID</p>
                      <p>{x?.bet_tx_Id}</p>
                    </div>
                  </div>
                </Col>
              ))
            : paymentTransaction?.map((x, i) => (
                <Col md="6" lg="4" key={i} className="m-0 p-1">
                  <div className="depositHistoryCard">
                    <div id="depositHistoryCardTop">
                      <p>{moment(x?.time).format("LLL")}</p>
                      {x?.status === 0 ? (
                        <h5 style={{ background: "var(--blueColor)" }}>
                          Pending
                        </h5>
                      ) : x?.status === 1 ? (
                        <h5 style={{ background: "green" }}>Success</h5>
                      ) : (
                        <h5>Failed</h5>
                      )}
                    </div>
                    <h1 id="depositHistoryCardHeading">
                      {parseFloat(x?.amount).toFixed(2)} INR
                    </h1>
                    <div id="depositHistoryCardDetails">
                      <p>Transaction ID</p>
                      <p>{x?.bet_tx_Id}</p>
                    </div>
                  </div>
                </Col>
              ))}
        </Row>
        {paymentTransaction?.length > 0 && (
          <PaginationComponent
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePagination}
          />
        )}
      </section>
    </section>
  );
};

export default BidsHistory;
