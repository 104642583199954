import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./LoginSlice";
import registerReducer from "./RegisterSlice";
import userStatus from './StatusState'; // Corrected from userStatue to userStatus
import configReducer from './ConfigSlice';
import dashboardDataReducer from './DashboardDataSlice'; // Ensure this matches the file name
import profileDataReducer from './ProfileDataSlice'; // Ensure this matches the file name
import incomeDataReducer from './IncomeWallet'
export default configureStore({
  reducer: {
    loginDisplay: loginReducer,
    registerDisplay: registerReducer,
    userStatus: userStatus,
    config: configReducer,
    dashboardData: dashboardDataReducer,
    profileData: profileDataReducer,
    incomeData: incomeDataReducer
  },
});
