import React from "react";
import "./Header.css";
import { MdCasino, MdGamepad, MdOutlineCasino } from "react-icons/md";
import { IoGameControllerSharp, IoGiftSharp } from "react-icons/io5";
import { FaUserAlt, FaHome } from "react-icons/fa";
import { FaMoneyBillWave, FaPeopleGroup } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
const MobileHeader = () => {
  const navigate = useNavigate();

  const mobileHeaderData = [
    { title: "Games", icon: <MdGamepad />, navigateLink: "/" },
    { title: "Bids", icon: <IoGameControllerSharp />, navigateLink: "/bets" },
    { title: "Home", icon: <FaHome />, navigateLink: "/dashboard" },
    { title: "Incomes", icon: <FaMoneyBillWave />, navigateLink: "/incomes" },
    { title: "Team", icon: <FaPeopleGroup />, navigateLink: "/direct-team" },
    { title: "Profile", icon: <FaUserAlt />, navigateLink: "/profile" },
  ];

  return (
    <section className="mobileHeader">
      {mobileHeaderData?.map((x, i) => {
        return (
          <div
            onClick={() => (x?.navigateLink ? navigate(x?.navigateLink) : null)}
          >
            {x?.icon ? <i>{x?.icon}</i> : <img src={x?.url} alt="" />}
            <p style={{ color: x.color }}>{x?.title}</p>
          </div>
        );
      })}
    </section>
  );
};

export default MobileHeader;
