// import React, { useEffect, useState } from "react";
// import { MdOutlineFilterList } from "react-icons/md";
// import { Col, Row } from "react-bootstrap";
// import { ApiPaths } from "./../../Config/ApiPaths";
// import Loader from "./../../Components/Loader/Loader";
// import "./GenerationTeam.css";
// import PaginationComponent from "../../Components/PaginationControls/PaginationControls";
// import useAxiosHelper from "./../../Common/AxiosHalper";
// import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
// import { useNavigate } from "react-router-dom";

// const GenerationTeam = () => {
//   const [selectLevel, setSelectLevel] = useState([]);
//   const [filterVisibility, setFilterVisibility] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [startDate, setStartDate] = useState("");
//   const [endDate, setEndDate] = useState("");
//   const [totalPages, setTotalPages] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [filterUserId, setFilterUserId] = useState("");
//   const [generationTeam, setGenerationTeam] = useState([]);
//   const [filterStatus, setFilterStatus] = useState("");
//   const [filterJoinDate, setFilterJoinDate] = useState("");
//   const [filterMobile, setFilterMobile] = useState("");
//   const { AxiosGet } = useAxiosHelper();
//   const navigate = useNavigate();
//   const [level, setLevel] = useState("1");
//   const [levelNumber, setLevelNumber] = useState("1");

//   useEffect(() => {
//     getLevels();
//   }, []);

//   const Levels = [
//     { type: 1, value: "Level 1" },
//     { type: 2, value: "Level 2" },
//     { type: 3, value: "Level 3" },
//     { type: 4, value: "Level 4" },
//     { type: 5, value: "Level 5" },
//     { type: 6, value: "Level 6" },
//     { type: 7, value: "Level 7" },
//     { type: 8, value: "Level 8" },
//     { type: 9, value: "Level 9" },
//     { type: 10, value: "Level 10" },
//   ];

//   const fetchIncome = (e) => {
//     const selectedLevel = e.target.value;
//     setLevel(selectedLevel);
//     const levelNumber = selectedLevel.split(" ")[1];
//     setLevelNumber(levelNumber);
//     console.log("Selected Level Number:", levelNumber);
//   };

//   const handlePagination = (page) => {
//     setCurrentPage(page);
//   };

//   const getLevels = async () => {
//     try {
//       const response = await AxiosGet(ApiPaths.getPackages);
//       const levels = response?.packages?.[0]?.level_income?.level;
//       console.log("Levels fetched:", levels);
//       setSelectLevel(levels);
//     } catch (error) {
//       console.error("Error fetching levels:", error);
//     }
//   };

//   useEffect(() => {
//     FetchData();
//   }, [levelNumber, currentPage]);

//   const FetchData = async (page = currentPage) => {
//     try {
//       const queryParams = {
//         page,
//         limit: 20,
//         levels: levelNumber,
//       };
//       console.log("Query Parameters:", queryParams);
//       setLoading(true);
//       const response = await AxiosGet(
//         `${ApiPaths.getTeams}?${new URLSearchParams(queryParams).toString()}`
//       );
//       setGenerationTeam(response?.data || []);
//       setTotalPages(response?.totalPages || 1);
//     } catch (error) {
//       console.error("Error fetching generation team data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const filterReset = () => {
//     setFilterUserId("");
//     setFilterJoinDate("");
//     setFilterStatus("");
//     FetchData(1);
//   };

//   return (
//     <section className="mainContent">
//       {loading && <Loader />}
//       <ProfileHeader />
//       <section className="paymentHistoryTabs">
//         <div>
//           <h1 onClick={() => navigate("/direct-team")}>Direct Team</h1>
//           <h1
//             className="activePaymentHistoryTab"
//             onClick={() => navigate("/generation-team")}
//           >
//             Generation Team
//           </h1>
//         </div>
//       </section>
//       <section className="incomeContent">
//         <div className="incomeSelectBox">
//           <select value={level} onChange={(e) => fetchIncome(e)}>
//             {Levels.map((x, index) => (
//               <option key={index} value={x.value}>
//                 {x.value}
//               </option>
//             ))}
//           </select>
//         </div>
//         <section className="history">
//           <div className="table">
//             <table>
//               <thead>
//                 <tr>
//                   <th>S.No</th>
//                   <th>User Id</th>
//                   <th>Name</th>
//                   <th>Join Date</th>
//                   <th>Activation Date</th>
//                   <th>Sponsor ID (Name)</th>
//                   <th>Self Business</th>
//                   <th>Team Business</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {generationTeam.map((x, i) => (
//                   <tr key={i}>
//                     <td>{i + 1}</td>
//                     <td>{x?.username}</td>
//                     <td>{x?.name}</td>
//                     <td>{x.joining_date}</td>
//                     <td>{x?.Activation_date}</td>
//                     <td>
//                       {x?.sponsor_username} ({x?.sponsor_Name})
//                     </td>
//                     <td>{x?.self_investment}</td>
//                     <td>{x?.business}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//             {generationTeam.length === 0 && <p>No history yet</p>}
//           </div>
//           {generationTeam.length > 0 && (
//             <PaginationComponent
//               currentPage={currentPage}
//               totalPages={totalPages}
//               onPageChange={handlePagination}
//             />
//           )}
//         </section>
//       </section>
//     </section>
//   );
// };

// export default GenerationTeam;

import React, { useEffect, useState } from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { Col, Row } from "react-bootstrap";
import { ApiPaths } from "./../../Config/ApiPaths";
import Loader from "./../../Components/Loader/Loader";
import "./GenerationTeam.css";
import PaginationComponent from "../../Components/PaginationControls/PaginationControls";
import useAxiosHelper from "./../../Common/AxiosHalper";
import ProfileHeader from "../../Components/ProfileHeader/ProfileHeader";
import { useNavigate } from "react-router-dom";

const GenerationTeam = () => {
  const [selectLevel, setSelectLevel] = useState([]);
  const [filterVisibility, setFilterVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterUserId, setFilterUserId] = useState("");
  const [generationTeam, setGenerationTeam] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [filterJoinDate, setFilterJoinDate] = useState("");
  const [filterMobile, setFilterMobile] = useState("");
  const { AxiosGet } = useAxiosHelper();
  const navigate = useNavigate();
  const [level, setLevel] = useState("1");
  const [levelNumber, setLevelNumber] = useState("1");
  const itemsPerPage = 20; // Number of items per page

  useEffect(() => {
    getLevels();
  }, []);

  const Levels = [
    { type: 1, value: "Level 1" },
    { type: 2, value: "Level 2" },
    { type: 3, value: "Level 3" },
    { type: 4, value: "Level 4" },
    { type: 5, value: "Level 5" },
    { type: 6, value: "Level 6" },
    { type: 7, value: "Level 7" },
    { type: 8, value: "Level 8" },
    { type: 9, value: "Level 9" },
    { type: 10, value: "Level 10" },
  ];

  const fetchIncome = (e) => {
    const selectedLevel = e.target.value;
    setLevel(selectedLevel);
    const levelNumber = selectedLevel.split(" ")[1];
    setLevelNumber(levelNumber);
    console.log("Selected Level Number:", levelNumber);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const getLevels = async () => {
    try {
      const response = await AxiosGet(ApiPaths.getPackages);
      const levels = response?.packages?.[0]?.level_income?.level;
      console.log("Levels fetched:", levels);
      setSelectLevel(levels);
    } catch (error) {
      console.error("Error fetching levels:", error);
    }
  };

  useEffect(() => {
    FetchData();
  }, [levelNumber, currentPage]);

  const FetchData = async (page = currentPage) => {
    try {
      const queryParams = {
        page,
        limit: itemsPerPage,
        levels: levelNumber,
      };
      console.log("Query Parameters:", queryParams);
      setLoading(true);
      const response = await AxiosGet(
        `${ApiPaths.getTeams}?${new URLSearchParams(queryParams).toString()}`
      );
      setGenerationTeam(response?.data || []);
      setTotalPages(response?.totalPages || 1);
    } catch (error) {
      console.error("Error fetching generation team data:", error);
    } finally {
      setLoading(false);
    }
  };

  const filterReset = () => {
    setFilterUserId("");
    setFilterJoinDate("");
    setFilterStatus("");
    FetchData(1);
  };

  return (
    <section className="mainContent">
      {loading && <Loader />}
      <section className="paymentHistoryTabs">
        <div>
          <h1 onClick={() => navigate("/direct-team")}>Direct Team</h1>
          <h1
            className="activePaymentHistoryTab"
            onClick={() => navigate("/generation-team")}
          >
            Generation Team
          </h1>
        </div>
      </section>
      <section className="incomeContent">
        <div className="incomeSelectBox">
          <select value={level} onChange={(e) => fetchIncome(e)}>
            {Levels.map((x, index) => (
              <option key={index} value={x.value}>
                {x.value}
              </option>
            ))}
          </select>
        </div>
        <section className="history">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>User Id</th>
                  <th>Name</th>
                  <th>Join Date</th>
                  <th>Activation Date</th>
                  <th>Sponsor ID (Name)</th>
                  <th>Self Business</th>
                  <th>Team Business</th>
                </tr>
              </thead>
              <tbody>
                {generationTeam.map((x, i) => {
                  // Calculate the global row number
                  const globalRowNumber =
                    (currentPage - 1) * itemsPerPage + i + 1;
                  return (
                    <tr key={i}>
                      <td>{globalRowNumber}</td>
                      <td>{x?.username}</td>
                      <td>{x?.name}</td>
                      <td>{x.joining_date}</td>
                      <td>{x?.Activation_date}</td>
                      <td>
                        {x?.sponsor_username} ({x?.sponsor_Name})
                      </td>
                      <td>{x?.self_investment}</td>
                      <td>{x?.business}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {generationTeam.length === 0 && <p>No history yet</p>}
          </div>
          {generationTeam.length > 0 && (
            <PaginationComponent
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePagination}
            />
          )}
        </section>
      </section>
    </section>
  );
};

export default GenerationTeam;
