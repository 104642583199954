import React from "react";
import { Carousel } from "antd";
import "./Banner.css";
import Banner1 from "./../../Assets/Images/banner1.jpg";
import Banner2 from "./../../Assets/Images/banner2.jpg";
const Banner = () => {
  const onChange = (currentSlide) => {
    // console.log(currentSlide);
  };
  return (
    <Carousel afterChange={onChange} autoplay loop>
      <div className="dashboardBannerImage">
        <img src={Banner1} alt="banner" />
      </div>
      <div className="dashboardBannerImage">
        <img src={Banner2} alt="banner" />
      </div>
    </Carousel>
  );
};
export default Banner;
